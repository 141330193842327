import { Delete, PersonAdd } from '@mui/icons-material';
import { useOnlineStatus } from '../../../Logic_Client';
import { DataNode } from '../../../Logic_Client/DataModels';
import React, { useCallback, useState } from 'react'
import NodeAncestorNavButton from './NodeAncestorNavButton';
import SharingModal from './SharingModal'
import _ from 'lodash';
import { UpdateNodesRequestItem } from '../../../Logic_Server/RequestInterfaces/NodeRequestInterfaces';
import { useModal } from '../../../Logic_Client/hooks/useModal';
import DeleteNodeModal from './DeleteNodeModal';

interface ContextBarProps {
    parents: DataNode<any>[],
    currentNode: DataNode<any>,
    setCurrentNode: (id: string | null) => void,
    updateNodes: (data: UpdateNodesRequestItem[]) => Promise<DataNode<any>[] | null>,
    deleteNodes: (ids: string[], recurse: boolean) => Promise<DataNode<any>[] | null>,
}

export default function NodeContextBar({ parents, currentNode, setCurrentNode, updateNodes, deleteNodes }: ContextBarProps) {
    const { openModal, closeModal } = useModal();
    const online = useOnlineStatus();

    const onDelete = useCallback((result: string) => {
        closeModal();
        if (result !== "deleted") return;

        if (parents.length > 0)
            setCurrentNode(parents[0].id);
        else setCurrentNode(null)
    }, [deleteNodes])

    const onShare = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.preventDefault();
        openModal(<SharingModal node={currentNode} onClose={closeModal} />);
    }


    return (
        <div id='node-context-bar'>
            <NodeAncestorNavButton
                parents={parents}
                btnClassName='btn-text'
            />
            {/* Delete Button */}
            {/* TODO move to dropdown */}
            {/* TODO on right click, popup ??Delete subtree?? */}
            <button
                disabled={!online || currentNode!.id === "temp"}
                className='context-bar-btn btn-text'
                onClick={() => {
                    openModal(<DeleteNodeModal node={currentNode} onClose={onDelete} message={currentNode.childrenIds.length > 0 ?
                        `This will delete all subdata as well. Continue?` : ""} />)
                }}>
                <Delete />
            </button>

            {/* Share Button */}
            <button className='context-bar-btn btn-text' onClick={onShare}>
                <PersonAdd />
            </button>
        </div >
    )
}
