import { DeleteForever, Undo } from '@mui/icons-material';
import { useDataNodes } from '../../../Logic_Client';
import { DataNode } from '../../../Logic_Client/DataModels';

interface DeleteNodeModalProps {
  node: DataNode<any>,
  onClose: (value: string) => void;
  message?: string
}

export default function DeleteNodeModal({ node, onClose, message }: DeleteNodeModalProps) {
  const { deleteNodes } = useDataNodes();
  function deleteNode() {
    deleteNodes([node.id], true);
    onClose("deleted");
  }

  return (
    <div className='delete-node-modal simple-modal'>
      <h2 className='modal-title'>Delete '{node.title}'?</h2>
      <span className='modal-content'>{message}</span>
      <button className='modal-cancel btn-cancel' onClick={() => onClose("cancelled")}>
        <Undo />
        Cancel
      </button>
      <button className='modal-confirm btn-delete' onClick={deleteNode}>
        <DeleteForever />
        Delete
      </button>
    </div>
  )
}
