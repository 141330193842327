import { TaskData } from "../../../Logic_Client/DataModels";
import { NodeListItemProps } from "../GetTypeView";
import { useEffect, useState } from "react";
import { ArrowRightOutlined, DragHandle } from "@mui/icons-material";
import update from 'immutability-helper';
import { useDebugMode } from "../../../Logic_Client";

export default function TaskListItem({
    node: dataNode,
    onChange,
    onContextMenu,
    onSelected,
    dragHandle,
    disabled
}: NodeListItemProps<TaskData>) {
    const [node, setNode] = useState(dataNode);
    const [debug] = useDebugMode();

    useEffect(() => {
        setNode(dataNode);
    }, [dataNode])

    const onNodeDataChange = (evt) => {
        const name = evt.target.name;
        const value = name === "completion" ? (evt.target.checked ? 1 : 0) : evt.target.value //Checkboxes store their value in a weird way

        // If the data has actually changed
        if (node.data[name] !== value) {
            const newNode = update(node, {
                data: {
                    [name]: { $set: value }
                }
            })
            setNode(newNode);
            onChange?.(newNode);
        }
    }

    return (
        <div className="task-list-item">
            {/* Checkbox */}
            <input type="checkbox"
                disabled={disabled}
                className="task-list-item_completion"
                name="completion"
                checked={node.data.completion === 1}
                onChange={onNodeDataChange}
            />

            <button className="list-item-content"
                onClick={() => { onSelected?.(node) }}
                onContextMenu={evt => { onContextMenu?.(evt, node) }}>
                {node.title}
            </button>

            <span className="drag-handle" ref={dragHandle}>
                <DragHandle />
            </span>
            {debug &&
                <div>
                    {node.data.priority}
                </div>
            }
        </div >
    )
}