import { createContext, useContext, useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import update from 'immutability-helper';

import * as _ from 'lodash'
import axios from 'axios';
import qs from 'qs';
import { DTOTools } from '../DataModels';
import DataNode from '../DataModels/DataNode';
import useRefState from './useRefState';
import { useUser } from './useUser';
import { DataNodeDTO } from '../../Logic_Server/DTOs';
import {
    UpdateNodesRequestItem,
    GetNodesByUser,
    GetNodesResponse,
    CreateNodesRequest,
    CreateNodesResponse,
    UpdateNodesRequest,
    UpdateNodesResponse,
    DeleteNodesRequest,
    DeleteNodesResponse,
    GetNodesById,
    GetNodesBySearchQuery
} from '../../Logic_Server/RequestInterfaces/NodeRequestInterfaces';
import useDebounced, { useIdGroupedDebounce } from './useDebounced';
import API from '../AxiosWrapper';

export interface DataNodesHook {
    currentNode: DataNode<any> | undefined,
    childNodes: DataNode<any>[],
    parentNodes: DataNode<any>[],
    setCurrentNode: (id: string | null) => void,
    createNodes: (nodes: Partial<DataNode<any>>[]) => Promise<DataNode<any>[]>,
    getRoots: () => Promise<DataNode<any>[]>,
    getNodes: (ids: string | string[], descendantDepth?: number, ancestorDepth?: number) => Promise<DataNode<any>[]>,
    searchNodes: (query: string) => Promise<DataNode<any>[]>
    updateNodes: (data: UpdateNodesRequestItem[]) => Promise<DataNode<any>[]>,
    deleteNodes: (ids: string[], recurse: boolean) => Promise<DataNode<any>[]>,
}

export const DataNodesContext = createContext<DataNodesHook>({
    currentNode: undefined,
    childNodes: [],
    parentNodes: [],
    setCurrentNode: () => { },
    createNodes: () => { return Promise.resolve([]) },
    getRoots: () => { return Promise.resolve([]) },
    getNodes: () => { return Promise.resolve([]) },
    searchNodes: () => { return Promise.resolve([]) },
    updateNodes: () => { return Promise.resolve([]) },
    deleteNodes: () => { return Promise.resolve([]) },
})

export const useDataNodes = () => useContext(DataNodesContext);

export function DataNodesProvider({ children }) {
    const dataNodesHook = useNodes();

    return (
        <DataNodesContext.Provider value={dataNodesHook}>
            {children}
        </DataNodesContext.Provider>
    )
}

// TODO write tests
function useNodes(): DataNodesHook {
    const [activeNode, setActiveNode] = useRefState<DataNode<any> | undefined>(undefined);
    const [children, setChildren] = useRefState<DataNode<any>[]>([]);
    const [parents, setParents] = useRefState<DataNode<any>[]>([]);
    const { user } = useUser('/login');
    // const [getPref, setPref] = useLocalPrefs(user.id);

    const navigate = useNavigate();
    useEffect(() => {
        const msgHandler = evt => {
            switch (evt.data.msg) {
                case 'getUpdate':
                    refetch(activeNode.current?.id ?? null);
                    break;
                default:
                    console.log("Recieved SW message", evt.data);
            }
        }
        navigator.serviceWorker.addEventListener('message', msgHandler)

        return () => {
            navigator.serviceWorker.removeEventListener('message', msgHandler)
        }
    }, [activeNode.current])


    useEffect(() => {
        const onServiceWorkerMessage = (event: MessageEvent) => {
            if (event.data.msg === "swActivated") {
                refetch(activeNode.current?.id ?? null);
            } else if (event.data.msg === "idsUpdated") {
                if (event.data.changedIds) {
                    if (activeNode.current && event.data.changedIds.has(activeNode.current.id)) {
                        setActiveNode(update(activeNode.current, {
                            id: { $set: event.data.changedIds.get(activeNode.current.id) }
                        }))
                    }

                    let newChildren;
                    children.current.forEach((child, index) => {
                        if (event.data.changedIds.has(child.id)) {
                            const newChild: DataNode<any> = child.clone();
                            newChild.id = event.data.changedIds.get(child.id);

                            newChildren = update(children.current, {
                                $splice: [[index, 1]],
                                $push: [newChild]
                            })
                        }
                    })

                    if (newChildren) {
                        console.log("OnServiceWorker idsUpdated:", newChildren.length);
                        setChildren(newChildren);
                    }

                    let newParents;
                    parents.current.forEach((parent, index) => {
                        if (event.data.changedIds.has(parent.id)) {
                            const newParent = parent.clone();
                            newParent.id = event.data.changedIds.get(parent.id);

                            newParents = update(parents.current, {
                                $splice: [[index, 1]],
                                $push: [newParent]
                            })
                        }
                    })

                    if (newParents) {
                        setParents(newParents);
                    }
                }
            }
        }

        navigator.serviceWorker.addEventListener('message', onServiceWorkerMessage);
        return () => {
            navigator.serviceWorker.removeEventListener('message', onServiceWorkerMessage);
        }
    }, [activeNode.current, children, parents])

    //#region REFETCHING

    async function refetch(id: string | null) {
        // Going to a node
        if (id) {
            // setPref("last-node", id);
            await getNodes([id], 1, 1).then(nodes => {
                if (nodes.length > 0) {
                    const { node, parents, children } = extractRelations(id, nodes);


                    setActiveNode(node);
                    updateChildrenIfNew(children);
                    updateParentsIfNew(parents);
                    // if (id !== activeNode.current?.id) {
                    navigate(`/app/data/${id}`);
                    // }
                }
            })
        }
        // Display roots
        else {
            // setPref("last-node", null);
            await getRoots().then(roots => {
                setActiveNode(undefined);
                setParents([]);
                // setChildren([]);
                updateChildrenIfNew(roots)
                navigate(`/app/home`);
            })
        }
    }

    const updateParentsIfNew = got => {
        // If the retrieved parents are different from what's currently stored
        if (got.length !== parents.current.length) {
            setParents(got);
            return;
        }

        const parentIds = new Map(parents.current.map(x => [x.id, x]));

        let update = false;

        update = !got.every(x => {
            if (parentIds.has(x.id)) {
                if (_.isEqual(x, parentIds.get(x.id)))
                    return true
            }
            return false
        })

        if (update) {
            setParents(got);
        }
    }
    const updateChildrenIfNew = got => {
        // If the retrieved children are different from what's currently stored
        if (got.length !== children.current.length) {
            setChildren(got);
            return;
        }

        const childIds = new Map(children.current.map(x => [x.id, x]));

        let update = false;

        update = !got.every(x => {
            if (childIds.has(x.id)) {
                if (_.isEqual(x, childIds.get(x.id)))
                    return true
            }
            return false
        })

        if (update) {
            setChildren(got);
        }
    }


    function extractRelations(nodeId: string, nodes: DataNode<any>[]) {
        const node = nodes.find(x => x.id === nodeId);
        if (!node) throw new Error(`Failed to find node: ${nodeId} in provided collection`);

        const parents: DataNode<any>[] = [], children: DataNode<any>[] = [];

        nodes.forEach(x => {
            if (x.id === nodeId)
                return;
            else if (node.parentIds.includes(x.id))
                parents.push(x);
            else if (node.childrenIds.includes(x.id))
                children.push(x);
        });
        return { node, parents, children };
    }

    //#endregion

    //TODO this should be merged with, or mirror _refetchActiveNode
    function setCurrentNode(id: string | null) {
        if (id) {
            const split = window.location.href.split('/');
            // if (id === split[split.length - 1]) {
            refetch(id);
            // navigate(`/app/data/${id}`)
        } else {
            setActiveNode(undefined);
            navigate("/app/home");
        }
    }

    async function getRoots(): Promise<DataNode<any>[]> {
        if (!user) throw new Error("Cannot fetch roots while unauthenticated!");

        const req: GetNodesByUser = {
            method: "GET",
            query: {
                authToken: await user.token
            }
        }
        return axios.get("/api/nodes", {
            params: req.query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" })
            }
        })
            .then((res) => {
                const resp: GetNodesResponse = res.data;
                if (resp.errors) {
                    // TODO handle errors
                    console.error(resp.errors)
                }

                return resp.found.map(node => DTOTools.DataNodeDTOToObject(node))
            })
            .catch(err => {
                if (err.response)
                    console.error("ERROR:", err.response.data, err.response.status, err.response.headers)
                // setRerender(!rerender);
                return [];
            })
    }

    async function createNodes(nodes: Partial<DataNode<any>>[]): Promise<DataNode<any>[]> {
        if (!user) throw new Error("Cannot create nodes while unauthenticated!");

        if (nodes.length === 0) return Promise.resolve([]);
        const req: CreateNodesRequest = {
            body: {
                authToken: await user.token,
                nodes: nodes.map((node, index) => {
                    if (!node.dataType) throw new Error("dataType param required to create node")
                    if (!node.data) throw new Error("data param required to create node")
                    if (!node.title) throw new Error("title param required to create node")

                    return {
                        ...node as DataNode<any>,
                        tempId: (Date.now() + index).toString()
                    }
                })
            }
        }

        return axios.post(`/api/nodes`, req.body)
            .then(res => {
                const resp: CreateNodesResponse = res.data;

                if (resp.errors)
                    console.error(resp.errors);

                refetch(activeNode.current?.id ?? null);

                return resp.created.map(node => DTOTools.DataNodeDTOToObject(node as DataNodeDTO));
            })
            .catch(err => {
                console.error(err.response)
                return [];
            })
    }

    /**
     * @param ids string = userId, string[] = nodeId(s)
     */
    async function getNodes(ids: string | string[], descendantDepth?: number, ancestorDepth?: number): Promise<DataNode<any>[]> {
        if (!user) throw new Error("Cannot fetch nodes while unauthenticated!");
        if (Array.isArray(ids) && ids.length === 0) return Promise.resolve([]);

        let req;
        // TODO get nodes by date
        if (Array.isArray(ids)) {
            // Getting nodes by nodeId
            const request: GetNodesById = {
                method: "GET",
                query: {
                    authToken: await user.token,
                    nodeIds: ids,
                    descendantDepth,
                    ancestorDepth
                }
            }
            req = request;
        } else {
            // Getting nodes by userId
            const request: GetNodesByUser =
            {
                method: "GET",
                query: {
                    authToken: await user.token,
                    descendantDepth,
                    ancestorDepth
                }
            }
            req = request;
        }

        return axios.get("/api/nodes", {
            params: req.query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" })
            }
        })
            .then(async res => {
                const resp: GetNodesResponse = res.data;
                if (resp.errors) {
                    console.error(resp.errors)
                }

                const userToken = await user.token;
                return resp.found.map(node => DTOTools.DataNodeDTOToObject(node, userToken))
            })
            .catch(err => {
                console.error(err.response)
                return [];
            })
    }

    async function searchNodes(query: string): Promise<DataNode<any>[]> {
        if (query === "" || !user) return [];
        const req: GetNodesBySearchQuery = {
            method: "GET",
            query: {
                authToken: await user.token,
                searchQuery: query
            }
        }

        const response = await API<GetNodesResponse>("/nodes", req);
        return response.found.map(x => DTOTools.DataNodeDTOToObject(x));
    }

    // TODO add set/update permissions function
    async function updateNodes(data: UpdateNodesRequestItem[]): Promise<DataNode<any>[]> {
        if (!user) throw new Error("Cannot update nodes while unauthenticated!");
        if (data.length === 0) return Promise.resolve([]);

        const req: UpdateNodesRequest = {
            method: "PUT",
            body: {
                authToken: await user.token,
                nodes: data
            }
        }

        return axios.put("/api/nodes", req.body)
            .then(res => {
                const resp: UpdateNodesResponse = res.data;
                if (resp.errors) console.error(resp.errors);

                const DTOs: DataNodeDTO[] = resp.updated;

                // TODO Refetch is only relevant for the SW
                // Currently, it reroutes the app whenever a node is updated. Bad...
                if (DTOs.some(x => x.id === activeNode.current?.id)) {
                    // refetch(activeNode.current?.id ?? null);
                }
                else {
                    // refetch(activeNode.current?.id ?? null)
                }

                return DTOs.map(node => DTOTools.DataNodeDTOToObject(node))
            })
            .catch(err => {
                console.error(err.response)
                return [];
            })
    }

    async function deleteNodes(ids: string[], recurse: boolean): Promise<DataNode<any>[]> {
        if (!user) throw new Error("Cannot delete nodes while unauthenticated!");
        if (ids.length === 0) return Promise.resolve([]);

        const req: DeleteNodesRequest = {
            method: "DELETE",
            query: {
                authToken: await user.token,
                nodeIds: ids,
                recurse
            }
        }
        return axios.delete("/api/nodes", {
            params: req.query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" })
            }
        })
            .then(res => {
                //TODO Should cause a rerender to update the visual state...
                const resp: DeleteNodesResponse = res.data;
                if (resp.errors)
                    console.error(resp.errors);

                return resp.deleted.map(node => DTOTools.DataNodeDTOToObject(node));
            })
            .catch(err => {
                console.error(err.response)
                return [];
            })
    }


    if (activeNode.current)
        document.title = `Wayfinder - ${activeNode.current.title}`
    else
        document.title = `Wayfinder` //TODO add "- Project View" or whatever becomes appropriate

    return {
        currentNode: activeNode.current,
        childNodes: children.current,
        parentNodes: parents.current,
        setCurrentNode,
        createNodes,
        getRoots,
        getNodes,
        searchNodes,
        updateNodes,
        deleteNodes,
    }
}