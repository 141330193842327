import { useDataNodes, useOnlineStatus, useUser } from "../Logic_Client"
import { DataNode } from "../Logic_Client/DataModels";
import Dashboard from "./Dashboard"
import { getNodeDataEditorView } from "../Components/DataNodes/GetTypeView";
import { useEffect, useState } from "react";
import { Editable, RenderElementProps, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor, Editor, Node, Point, Transforms } from "slate";

import NodeContextBar from "../Components/DataNodes/Generic/NodeContextBar";
import ContentContextBar from "../Components/DataNodes/ContentContextBar";
import { renderLeaf } from '../Components/SlateJs/FuckEncapsulation';
import { Tasklist } from "../Components/DataNodes/TASK/Tasklist";
import useDebounced, { useIdGroupedDebounce } from "../Logic_Client/hooks/useDebounced";
import { UpdateNodesRequestItem } from "../Logic_Server/RequestInterfaces/NodeRequestInterfaces";
import { useNavigate } from "react-router-dom";

export function NodeViewer() {
    const nodeProps = useDataNodes();
    const { currentNode, parentNodes: parents, setCurrentNode, updateNodes } = nodeProps;
    const online = useOnlineStatus();
    const [dataEditorOpen, setDataEditorOpen] = useState(true);
    const [editor] = useState(() => withDataNodeContent(withReact(withHistory(createEditor()))))
    const [title, setTitle] = useState<string>("");
    const { user } = useUser();
    const navigate = useNavigate();


    /* #region Debounced Update Functions */

    const updateTitle = useDebounced((newTitle: string) => {
        if (!currentNode) return;
        updateNodes([{ id: currentNode.id, title: newTitle }])
    }, 1000, [currentNode, updateNodes]);
    const updateContent = useDebounced((newContent: any) => {
        if (!currentNode) return;
        updateNodes([{ id: currentNode.id, content: newContent }])
    }, 1000, [currentNode, updateNodes]);
    const updateNode = useIdGroupedDebounce((request: UpdateNodesRequestItem[]) => {
        updateNodes(request)
    }, 1000, [updateNodes]);

    /* #endregion */


    useEffect(() => {
        if (!user) return;

        const id = window.location.pathname.split('data/')[1];
        if (id === currentNode?.id) return;

        if (id) {
            setCurrentNode(id);
        }
    }, [window.location.href, user])

    useEffect(() => {
        if (!currentNode) return;

        let newTitle, newContent;
        //TODO REMOVE POST DB UPDATE
        if (currentNode.data.title) {
            newTitle = currentNode.data.title ?? "Title"
            setTitle(newTitle);
            currentNode.data.title = null;
        } else {
            setTitle(currentNode?.title ?? "Title");
        }

        //TODO REMOVE POST DB UPDATE
        if (currentNode.data.description) {
            newContent = [{ type: 'paragraph', children: [{ text: currentNode.data.description }] }];
            resetEditor(editor, { nodes: newContent });
            currentNode.data.description = null;
        } else {
            resetEditor(editor, { nodes: currentNode?.content ?? defaultContent });
        }

        //TODO REMOVE POST DB UPDATE
        if (newTitle || newContent) {
            updateNode({
                id: currentNode.id,
                content: newContent,
                title: newTitle,
                data: currentNode.data
            })
        }
    }, [currentNode?.id])


    /* #region SLATE DRAWERS */

    function renderElement(renderProps: RenderElementProps): JSX.Element {
        switch (renderProps.element.type) {
            // case 'tasklist': {
            //     return <Tasklist
            //         {...renderProps}
            //         parentNode={currentNode!}
            //         createNodes={createNodes}
            //         updateNode={updateData}
            //     />
            // }
            default: {
                return <p {...renderProps.attributes}>{renderProps.children}</p>
            }
        }
    }

    /* #endregion */


    const onDataEditorChange = (node: DataNode<any>) => {
        if (!node) { console.error("How are you editing a node that doesn't exist?", node); return };
        // TODO only request what's changed
        updateNode(node);
    }
    const onContentChange = (value) => {
        if (!currentNode) return;

        const incoming = JSON.stringify(value);
        const current = JSON.stringify(currentNode.content)
        if (incoming !== current) {
            updateContent(value);
        }
    }

    if (currentNode) {
        const DataEditor = getNodeDataEditorView(currentNode.dataType);
        const content = currentNode.content ?? defaultContent;
        return (
            <>
                <NodeContextBar {...nodeProps} currentNode={currentNode!} parents={parents} />
                <div id="node-viewer">
                    <input type='text'
                        className="title"
                        value={title}
                        onChange={evt => {
                            setTitle(evt.target.value);
                            updateTitle(evt.target.value);
                        }}
                        disabled={!online}
                    />

                    <div className="data-editor-container">
                        {
                            dataEditorOpen &&
                            <DataEditor
                                className="data-editor"
                                node={currentNode}
                                onChange={onDataEditorChange}
                                disabled={!online}
                            />
                        }
                    </div>

                    <div className="content-editor">
                        <Slate
                            editor={editor}
                            value={content}
                            onChange={onContentChange}
                        >
                            <Editable
                                renderElement={renderElement}
                                renderLeaf={renderLeaf}
                                placeholder="Add a description..."
                            />
                        </Slate>
                        <Tasklist {...nodeProps} updateNode={updateNode} parentNode={currentNode} />
                    </div>
                </div >
                <ContentContextBar editor={editor} />
            </>
        )
    } else {
        return <Dashboard />
    }
}

const withDataNodeContent = (editor: Editor) => {
    const { isVoid } = editor;

    editor.isVoid = element => {
        return element.type === 'tasklist' ? true : isVoid(element);
    }

    return editor;
}

/**
* resetNodes resets the value of the editor.
* It should be noted that passing the `at` parameter may cause a "Cannot resolve a DOM point from Slate point" error.
*/
function resetEditor<T extends Node>(
    editor: Editor,
    options: {
        nodes?: Node | Node[],
        at?: Location
    } = {}
): void {
    const children = [...editor.children]

    children.forEach((node) => editor.apply({ type: 'remove_node', path: [0], node }))

    if (options.nodes) {
        const nodes = Node.isNode(options.nodes) ? [options.nodes] : options.nodes

        nodes.forEach((node, i) => editor.apply({ type: 'insert_node', path: [i], node: node }))
    }

    try {
        const point = options.at && Point.isPoint(options.at)
            ? options.at
            : Editor.end(editor, [])

        if (point) {
            Transforms.select(editor, point)
        }
    } catch (e) {
        console.log(e);
    }
}

const defaultContent = [{ type: 'paragraph', children: [{ text: 'Here is some starter text...' }] }];