import { AddCircle, Delete, DragHandle, Edit, MoveUp } from '@mui/icons-material';
import ReorderableList from '../../../Components/ReorderableList';
import { useDataNodes, useUser } from '../../../Logic_Client'
import { DataNode, TaskData } from '../../../Logic_Client/DataModels';
import { UpdateNodesRequestItem } from '../../../Logic_Server/RequestInterfaces/NodeRequestInterfaces';
import { useEffect, useState } from 'react'
import { useSelected } from 'slate-react';
import TaskListItem from './TaskListItem';
import update from 'immutability-helper';
import NewTaskModal from './NewTaskModal';
import { CircularProgress } from '@mui/material';
import { Item, ItemParams, Menu, useContextMenu } from 'react-contexify';
import { createPortal } from 'react-dom';
import "react-contexify/ReactContexify.css";
import DeleteNodeModal from '../Generic/DeleteNodeModal';
import { useModal } from '../../../Logic_Client/hooks/useModal';
import MoveNodeModal from '../Generic/MoveNodeModal';

type TaskListProps = {
    parentNode: DataNode<TaskData>,
    updateNode: (data: UpdateNodesRequestItem) => void
};

const ITEM_MENU_ID = 'list-item'

export function Tasklist({ parentNode, updateNode }: TaskListProps) {
    const selected = useSelected();
    const { setCurrentNode, deleteNodes } = useDataNodes();
    const [list, setList] = useState<DataNode<TaskData>[]>([])
    const { openModal, closeModal } = useModal();

    const { user } = useUser();
    const { show } = useContextMenu({
        id: ITEM_MENU_ID
    })

    const incomplete = list?.filter(x => x.data.completion !== 1);
    const complete = list?.filter(x => x.data.completion === 1);

    useEffect(() => {
        const populateList = async () => {
            if (!user) return;
            setList([]);
            const children = await parentNode.fetchChildren(await user.token);
            setList(children);
        }
        populateList();
    }, [parentNode, user])

    function displayItemMenu(event: React.MouseEvent, node: DataNode<TaskData>) {
        const box = event.currentTarget.getClientRects()[0]

        show({
            event,
            position: { x: box.x, y: box.y + box.height },
            props: {
                node
            }
        });
    }

    function handleChange(node: DataNode<TaskData>) {
        setList([...list.filter(x => x.id !== node.id), node]);
        if (node.data.completion === 1) node.data.priority = -1;
        updateNode(node);
    }

    function handleOrderChange(newIndexOrder: number[]): void {
        // Calculate new priorities
        const updatedList = update(incomplete, {
            $apply: (v) => {
                v.forEach((x, ind) => {
                    // Assign and update the priority of incomplete task
                    x.data.priority = newIndexOrder.indexOf(ind);
                    updateNode(x);
                });
                return v;
            }
        })
        setList([...updatedList, ...complete]);
    }

    function handleDeletedSubtask(id: string) {
        closeModal();
        setList(list.filter(x => x.id !== id));
        setCurrentNode(parentNode.id);
    }
    function handleMovedSubtask(id: string) {
        closeModal();
        setList(list.filter(x => x.id !== id));
        setCurrentNode(parentNode.id);
    }

    async function handleMenuItem(params: ItemParams<{ node: DataNode<any> }, any>) {
        const node = params.props!.node;
        switch (params.id) {
            case "edit": {
                setCurrentNode(node.id);
                break;
            }
            case "move": {
                openModal(<MoveNodeModal toMove={[node]} currentParent={parentNode} onClose={handleMovedSubtask} />);
                break;
            }
            case "delete": {
                openModal(<DeleteNodeModal node={node} onClose={result => {
                    if (result === "deleted") handleDeletedSubtask(node.id)
                }} />);
                break;
            }
        }
    }

    return (
        <div
            className={`task-list ${selected ? 'selected' : ''}`}
            contentEditable={false}
        >
            <h3 className="header"><DragHandle />Tasks:</h3>
            <div className="content">
                {
                    parentNode.childrenIds.length > 0
                        && list.length === 0 ?
                        <CircularProgress />
                        :
                        <ReorderableList onOrderChange={handleOrderChange} reverse>
                            {
                                incomplete.sort((a, b) => a.data.priority - b.data.priority).map(x =>
                                    <TaskListItem
                                        key={x.id}
                                        node={x}
                                        onSelected={node => setCurrentNode(node.id)}
                                        onChange={handleChange}
                                        onContextMenu={displayItemMenu}
                                    />
                                )
                            }
                        </ReorderableList>
                }
                <button className="task-list_add-btn" onClick={() => {
                    openModal(<NewTaskModal parentNode={parentNode} onClose={closeModal} />);
                }}>
                    <AddCircle />
                    New Subtask
                </button>
                {
                    complete.length > 0 &&
                    <h4 className='hr'>
                        COMPLETE
                    </h4>
                }
                {
                    complete.sort((a, b) => a.data.priority - b.data.priority).map(x =>
                        <TaskListItem
                            key={x.id}
                            node={x}
                            onSelected={node => setCurrentNode(node.id)}
                            onChange={handleChange}
                        />
                    )
                }
            </div>
            {
                createPortal(
                    <Menu id={ITEM_MENU_ID}>
                        <Item id="edit" onClick={handleMenuItem}>
                            Edit
                        </Item>
                        <Item id="move" onClick={handleMenuItem}>
                            Move
                        </Item>
                        <Item id="delete" onClick={handleMenuItem}>
                            Delete
                        </Item>
                    </Menu>,
                    document.getElementById("portal")!
                )
            }
        </div >
    )
}
