// TypeScript users only add this code
import { BaseEditor, Descendant, Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import NodeDataType from '../../Logic_Client/DataModels/NodeDataTypes';

import { Element } from 'slate';
import { useState } from 'react';
import { Tasklist } from '../../Components/DataNodes/TASK/Tasklist';

type Text = {
    text: string
} & FormatOptions;

type EmptyText = {
    text: ""
}

export type Mark =
    | "bold"
    | "italic"
    | "underline"
    | "linethrough"
    | "overline"
    | "code"

type FormatOptions = {
    bold?: boolean,
    italic?: boolean,
    underline?: boolean,
    linethrough?: boolean,
    overline?: boolean,
    code?: boolean,
}


type MentionElement = {
    type: 'mention',
    target: string,
    children: CustomText[]
}

type ParagraphElement = {
    type: 'paragraph'
    align?: string
    children: Descendant[]
}

type CodeBlockElement = {
    type: 'code',
    language?: string
}

type QuoteElement = {
    type: 'quote'
}

type DataNodeElement = {
    type: 'datanode',
    id: string,
    dataType: NodeDataType,
}

type TaskListElement = {
    type: 'tasklist',
    nodeIds: string[],
    parentId: string,
    children: EmptyText[]
}

export type CustomElement =
    | MentionElement
    | ParagraphElement
    | CodeBlockElement
    | QuoteElement
    | DataNodeElement
    | TaskListElement
// | EditableVoidElement

export type CustomText = Text | EmptyText;

declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor & HistoryEditor
        Element: CustomElement
        Text: CustomText
    }
}



export const renderElement = props => {
    switch (props.element.type) {
        case 'code': return <div className="code-block" {...props.attributes} >{props.children}</div>
        case 'quote': return <div className="quote-block" {...props.attributes} >{props.children}</div>
        case 'tasklist': return <Tasklist {...props} />
        // case 'editable-void': return <EditableVoid {...props}/>
        default: return <p {...props.attributes}>{props.children}</p>
    }
}

export const renderLeaf = ({ attributes, children, leaf }) => {
    const classes = (leaf.bold ? "bold" : "")
        + (leaf.italic ? " italic" : "")
        + (leaf.underline ? " underline" : "")
        + (leaf.linethrough ? " line-through" : "")
    return <span {...attributes}
        className={classes}
    >
        {children}
    </span >
}

export function setMark(editor: Editor, mark: Mark, value: any) {
    Editor.addMark(editor, mark, value);
}

export function toggleMark(editor: Editor, mark: Mark) {
    const [match] = Array.from(Editor.nodes(editor, {
        match: (n: any) => {
            return n[mark]
        },
    }))
    Editor.addMark(editor, mark, match ? false : true);
}

export function toggleType(editor: Editor, type: Pick<CustomElement, 'type'>) {
    const [match] = Array.from(Editor.nodes(editor, {
        match: (n: any) => n.type === type.type,
    }))

    Transforms.setNodes(
        editor,
        match ? { type: 'paragraph' } as any : type,
        { match: n => Editor.isBlock(editor, n) }
    )
}

export function insertNode(editor: Editor, node: CustomElement) {
    Transforms.insertNodes(editor, [node, { type: 'paragraph', children: [{ text: '' }] }]);
}


const EditableVoid = ({ attributes, children, element }) => {
    const [inputValue, setInputValue] = useState('')

    return (
        // Need contentEditable=false or Firefox has issues with certain input types.
        <div {...attributes} contentEditable={false}>
            <div>
                <h4>Name:</h4>
                <input type="text"
                    value={inputValue}
                    onChange={e => {
                        setInputValue(e.target.value)
                    }}
                />
                <h4>Left or right handed:</h4>
                <input type="radio"
                    name="handedness"
                    value="left"
                />{' '}
                Left
                <br />
                <input type="radio"
                    name="handedness"
                    value="right"
                />{' '}
                Right
                <h4>Tell us about yourself:</h4>
                <div>
                    Pretend this is a rich text editor. Woohoo! I have so much money!
                </div>
            </div>
            {children}
        </div>
    )
}