import { ProjectViewProps } from '..'
import { useOnlineStatus } from '../../../Logic_Client'

import { Box } from '@mui/system'


export default function TaskProjectView({
    currentNode: dataNode,
    setCurrentNode,
    id,
    className,
}: ProjectViewProps) {
    const online = useOnlineStatus();

    if (!dataNode) return null;
    const priority = dataNode.data.priority;
    const data = dataNode.data;

    // const onChange = (node: DataNode<TaskData>, property: string, value: any) => {
    //     let reqBody: UpdateNodesRequestBody[];
    //     if (property === "tags") {
    //         let remove;
    //         if (value.includes(":")) {
    //             remove = node.tags.find(x => x.includes(value.split(":")[0]))
    //         }

    //         reqBody = [{
    //             id: node.id,
    //             tagsToRemove: remove,
    //             tagsToAdd: value,
    //         }]
    //     } else {
    //         reqBody = [{
    //             id: node.id,
    //             data: { [property]: value }
    //         }]
    //     }
    //     updateNodes(reqBody)
    // }

    const onNavigate = (id: string | null) => {
        setCurrentNode(id)
    }
    return (
        <div id={id} className={`${className} task-project-view`} onClick={() => onNavigate(dataNode.id)}>
            <span className="title">
                {dataNode.title}
                {dataNode.data.content?.length > 0 ?
                    <span>{"  " + dataNode.data.content}</span>
                    :
                    null
                }
            </span>
        </div>
    )
}
