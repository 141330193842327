import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './Logic_Client';
import * as sw from './serviceWorkerRegistration'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import FAQ from './Pages/FAQ';
import LoginAndRegister from './Pages/LoginAndRegister';



const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);


rootElement!.style.setProperty("--viewable-height", window.innerHeight.toString() + "px");
rootElement!.style.setProperty("--soft-keyboard", "0");

// TODO This is a confusing solution for collapsing something when the software keyboard is open. I don't know how to handle boolean maths in CSS...
window!.addEventListener('resize', evt => {
    if (document.activeElement?.tagName === "INPUT") {
        rootElement!.style.setProperty("--soft-keyboard", "1");
    } else if (document.activeElement?.tagName === "DIV" && document.activeElement?.getAttribute("contenteditable") === "true") {
        rootElement!.style.setProperty("--soft-keyboard", "1");
    } else {
        rootElement!.style.setProperty("--soft-keyboard", "0");
    }
    rootElement!.style.setProperty("--viewable-height", window.innerHeight.toString() + "px");
});


root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <UserProvider>
            <Routes>
                <Route path='/app/*' element={<App />} />

                <Route path="/faq" element={<FAQ />} />

                <Route path="/login" element={<LoginAndRegister />} />

                <Route path="*" element={<Navigate to="/app" replace />} />
            </Routes>
        </UserProvider >
    </BrowserRouter>
    // </React.StrictMode>
);

sw.unregister();

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register(`./service-worker.js`, { scope: "/" })
//         .then(x => { console.log("Registered sw!", x) })
//         .catch(x => { console.log("Failed to register sw ", x) })
// } else alert("Your browser does not support service workers, and will likely not function as expected :(");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();