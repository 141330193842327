import { ResponseError } from "../Logic_Server";
import axios from "axios";
import qs from "qs";
import path from "path-browserify";

export interface BodyRequest { method: "POST" | "PUT" | "PATCH", body: any }
export interface QueryRequest { method: "GET" | "DELETE", query: any }
export type WayfinderRequest = BodyRequest | QueryRequest

export interface WayfinderResponse {
    errors?: ResponseError[]
}

export default async function API<T extends WayfinderResponse>(
    endpoint: string,
    requestObject: WayfinderRequest
): Promise<T> {
    if (!endpoint.startsWith("/api")) endpoint = path.join("/api", endpoint);
    switch (requestObject.method) {
        case "POST": {
            const res = (await axios.post(endpoint, requestObject.body)).data;
            return res;
        }
        case "GET": {
            const res = (await axios.get(endpoint, {
                params: requestObject.query,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" })
                }
            })).data;
            return res;
        }
        case "PUT": {
            const res = (await axios.put(endpoint, requestObject.body)).data;
            return res;
        }
        case "PATCH": {
            const res = (await axios.patch(endpoint, requestObject.body)).data;
            return res;
        }
        case "DELETE": {
            const res = (await axios.delete(endpoint, {
                params: requestObject.query,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" })
                }
            })).data;
            return res;
        }
    }
}