import React, { ReducerState, useEffect, useReducer, useRef, useState } from "react";

const useRefState = <T>(initialValue: T): [React.MutableRefObject<T>, any] => {
    const stateRef = useRef(initialValue);

    function reducer(state: any, newState: any) {
        stateRef.current = newState;
        return stateRef.current;
    }

    const [state, setState] = useReducer(reducer, initialValue);
    return [stateRef, setState];
};

export default useRefState;