import { DataNode, NodeDataType } from "../../Logic_Client/DataModels";
import { MutableRefObject } from "react";
import { ConnectDragSource } from "react-dnd";
import { Descendant } from "slate";
import DataEditor from "./TASK/DataEditor";
import TaskListItem from "./TASK/TaskListItem";



export type NodeListItemProps<T> = {
    node: DataNode<T>,
    parent?: DataNode<any>,
    onChange?: (node: DataNode<T>) => void,
    onContextMenu?: (event: React.MouseEvent, node: DataNode<T>) => void,
    onSelected?: (node: DataNode<T>) => void,
    disabled?: boolean,
    dragHandle?: ConnectDragSource
}

export function getNodeListItemView(dataType: string): React.FC<NodeListItemProps<any>> {
    switch (dataType) {
        case NodeDataType.Task: return TaskListItem;
        default: throw new Error(`${dataType} does not have a dataview for display`)
    }
}

export type DataEditorProps<T> = {
    node: DataNode<T>,
    onChange?: (node: DataNode<T>) => void,
    disabled?: boolean,
    className?: string
}

export function getNodeDataEditorView(dataType: string): React.FC<DataEditorProps<any>> {
    switch (dataType) {
        case NodeDataType.Task: return DataEditor;
        default: throw new Error(`${dataType} does not have a dataview for display`)
    }
}