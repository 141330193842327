import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { DataNode, DTOTools, TaskData } from '../../Logic_Client/DataModels'
import PlannerSubtaskView from './TodolistItem';
import update from 'immutability-helper';
// import { WeightParams } from 'Logic_Server/PlannerFunctions';
import { Slider } from '@mui/material';
import { UpdateNodesRequestItem } from '../../Logic_Server/RequestInterfaces/NodeRequestInterfaces';
import { useDataNodes, useUser } from '../../Logic_Client/hooks';
import { useLocation } from 'react-router-dom';
import { GetTodoListRequest, GetTodoListResponse } from '../../Logic_Server/RequestInterfaces/TodolistRequestInterfaces';

export default function Planner() {
    const { user } = useUser();
    const [nodes, setNodes] = useState<DataNode<TaskData>[]>([])
    const { updateNodes, setCurrentNode } = useDataNodes();
    const navState = useLocation().state;
    const [serverResponded, setServerResponded] = useState(false);

    const [numTasks, setNumTasks] = useState(3);
    const [weights, setWeights] = useState<any/* WeightParams */>({
        taskDepthWeight: 0.1,
        deadlineWeight: 1,
        taskCountWeight: 0.5
    })

    useEffect(() => {
        getNodes();
    }, [])

    async function getNodes() {
        const req: GetTodoListRequest = {
            method: "GET",
            query: {
                method: "TODO",
                userId: user!.id,
                numTasks,
                weights,
                subtree: navState.subtreeId
            }
        }
        axios.get("/api/tasks", { params: req.query })
            .then(nodes => {
                const resp: GetTodoListResponse = nodes.data;
                const list = resp.todo.map(x => DTOTools.DataNodeDTOToObject(x));
                setNodes(list);
                setServerResponded(true);
            })
            .catch(err => {
                console.error(err.response)
            })
    }

    const onTaskChange = (node: DataNode<TaskData>, property: string, value: any) => {
        if (property !== "completion") return;

        const req: UpdateNodesRequestItem[] = [
            {
                id: node.id,
                data: { completion: value }
            }
        ]
        updateNodes(req);

        const ind = nodes.indexOf(node);
        node.data.completion = value;

        setNodes(update(nodes, {
            $splice: [[ind, 1], [ind, 0, node]]
        }));
    }

    return (
        <>
            <div className='options-container'>
                <span className='slider-label'>Number of tasks</span>
                <Slider className='slider' defaultValue={3} min={1} max={10} step={1} onChange={(evt) => {
                    setNumTasks((evt.target as any).value)
                }} />
                <span className='slider-value'>{numTasks}</span>

                <button className='generate-button' onClick={getNodes}>
                    Generate!
                </button>
            </div>

            {
                serverResponded ?
                    nodes.length > 0 ? nodes.map(node =>
                        <PlannerSubtaskView
                            dataNode={node}
                            onClicked={setCurrentNode}
                            onDataChange={onTaskChange} />
                    )
                        :
                        <div>
                            Nothing to do!
                        </div>
                    :
                    <div>
                        Thinking...
                    </div>
            }
        </>
    )
}
