import React, { useRef } from 'react'
import { createPortal } from 'react-dom';

export interface PulloutProps extends React.HTMLAttributes<HTMLDivElement> {
    placement: 'left' | 'right' | 'top' | 'bottom';
    open: boolean,
    onClose?: () => void,
    cssSize?: string
}

export default function PulloutMenu({ children, placement, open, id, onClose, cssSize, className }: PulloutProps) {
    const container = useRef<HTMLDivElement>(null);


    const blur: React.MouseEventHandler<HTMLDivElement> = (evt) => {
        evt.preventDefault();
        onClose?.();
    }

    if (open) {
        return createPortal(
            <div id={id}
                ref={container}
                className={`${'pullout-menu'} ${placement} ${open ? 'open' : 'closed'}`}
                style={{ ["--size" as any]: cssSize ?? "40vw" }}
            >
                <div className={`pullout-menu_backdrop ${open ? 'open' : 'closed'}`} onClick={blur}></div>
                <div className={className}>
                    {children}
                </div>
            </div>,
            document.getElementById('portal')!)
    } else {
        return null;
    }
}
