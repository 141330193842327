import React, { useCallback } from 'react'
import DropTargetButton from '../../../Components/DropTargetButton';
import { useDataNodes } from '../../../Logic_Client';
import { DataNode, NodeDataType } from '../../../Logic_Client/DataModels';
import { UpdateNodesRequestItem } from '../../../Logic_Server/RequestInterfaces/NodeRequestInterfaces';

interface NodeAncestorNavButtonProps {
    parents: DataNode<any>[],
    btnClassName?: string,
    onClick?: (event: React.MouseEvent, targetId?: string) => void
}

export default function NodeAncestorNavButton({ parents, onClick, btnClassName }: NodeAncestorNavButtonProps) {
    const { updateNodes, setCurrentNode } = useDataNodes();
    const changeParent = useCallback((child: DataNode<any>, newParent: DataNode<any> | undefined) => {
        const reqBody: UpdateNodesRequestItem[] = [
            ...child.parentIds.map(par => ({
                id: par,
                childrenToRemove: [child.id]
            })),
            {
                id: child.id,
                parentsToRemove: child.parentIds,
                parentsToAdd: newParent ? [newParent.id] : undefined,
            }
        ]
        if (newParent) {
            if (child.parentIds.includes(newParent.id))
                console.error("Trying to add and remove the same parent... What?", reqBody);

            reqBody.push({
                id: newParent.id,
                childrenToAdd: [child.id]
            })
        }
        updateNodes(reqBody)
    }, [updateNodes])

    return (
        <span className='ancestor-nav-btns'>
            {
                parents?.length > 0 ?
                    parents.map(parent =>
                        <DropTargetButton
                            key={parent.title}
                            className={btnClassName}
                            accepts={[NodeDataType.Task]}
                            onDrop={(item) => { changeParent(item, parent); }}
                            onClick={event => {
                                if (onClick) onClick(event, parent.id)
                                else setCurrentNode(parent.id);
                            }}
                        >
                            {parent.title}
                        </DropTargetButton>)
                    :
                    <DropTargetButton
                        key={"home"}
                        className={btnClassName}
                        accepts={[NodeDataType.Task]}
                        onDrop={(item) => { changeParent(item, undefined) }}
                        onClick={event => {
                            if (onClick) onClick(event)
                            else setCurrentNode(null);
                        }}
                    >
                        Home
                    </DropTargetButton>
            }
        </span>
    )
}
