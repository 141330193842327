import { Button } from '@mui/material';
import React, { ReactNode } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

interface DropTargetProps /* extends React.HTMLAttributes<HTMLButtonElement> */ {
    children: ReactNode,
    accepts: string | string[],
    className?: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    onDrop?: (item, monitor: DropTargetMonitor<unknown, unknown>) => void,
}

const DropTargetButton = ({ children, accepts, onDrop, onClick, className }: DropTargetProps
) => {

    const [{ isOver }, drop] = useDrop(() => ({
        accept: accepts,
        collect: monitor => ({
            isOver: !!monitor.isOver()
        }),
        drop: (item, monitor) => {
            onDrop?.(item as any, monitor)
        }
    }));

    return (
        <button
            ref={drop}
            className={`${className} ${isOver ? " drag-hover" : ""}`}
            onClick={evt => onClick?.(evt)}>
            {children}
        </button>
    )
}

export default DropTargetButton;