import { AddCircle } from '@mui/icons-material';
import { DataNode, TaskData, NodeDataType } from '../Logic_Client/DataModels';
import { useEffect, useState } from 'react'
import { GetProjectView } from '../Components/DataNodes';
import { useDataNodes, useUser } from '../Logic_Client';


export default function Dashboard() {
    const dataNodes = useDataNodes();
    const { getRoots, createNodes } = dataNodes;
    const { user, loading } = useUser('/login');

    const [projects, setProjects] = useState<DataNode<any>[]>([]);

    useEffect(() => {
        if (loading || !user) return;

        getRoots().then(roots => {
            setProjects(roots?.sort((a, b) => a.data.priority - b.data.priority) ?? []);
        })
    }, [user, setProjects])


    // const onListOrderChange = (indices: number[]) => {
    //     console.log(indices);
    //     console.log(projects.map((x, ind) => ({ p: x.data.priority, i: ind, title: x.data.title })));

    //     const body: UpdateNodesRequestItem[] = projects.map((node, ind) => ({
    //         id: node.id,
    //         data: {
    //             title: node.data.title,
    //             priority: indices.indexOf(ind),
    //         }
    //     }))
    //     const updated = body.filter((x, ind) => projects[ind].data.priority !== x.data.priority);
    //     console.log(updated.map((x, ind) => ({ p: x.data.priority, i: ind, title: x.data.title })));

    //     updateNodes(updated)
    // }

    if (user) {
        return (
            <div id="home-view">
                {/* <ReorderableList
                    className="project-view__item-container"
                    onOrderChange={onListOrderChange}
                    reverse
                > */}
                {projects.map((item, index) => {
                    const View = GetProjectView(item.dataType)
                    return (
                        <View {...dataNodes} key={item.id} className={"home-view_item"} currentNode={item} />
                    )
                })}
                {/* </ReorderableList> */}

                <button id="home-view_add-btn" onClick={() => {

                    const temp: Partial<DataNode<TaskData>> = {
                        title: "New Project",
                        data: new TaskData({}),
                        dataType: NodeDataType.Task
                    }

                    createNodes([temp]).then(newNodes => {
                        if (newNodes) {
                            setProjects([...projects, ...newNodes]);
                        }
                    });
                }}>
                    <AddCircle />
                </button>
            </div >
        )
    } else {
        // TODO return loading/authenticating page
        return null;
    }
}