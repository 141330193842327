import DataNode from "./DataNode";
import { DataNodeDTO } from "../../Logic_Server/DTOs";
import { LocalDBDataNode } from ".";

/**
 * NOTE: the lastFetched property returned by this function actually represents the lastUpdate from the server
 */
export function DataNodeDTOToObject(node: DataNodeDTO, authToken?: string): DataNode<any> {
    let dataNode = new DataNode<any>(
        node.id,
        node.userId,
        node.title ?? "", //TODO post-mig non-null
        node.data,
        node.dataType,
        node.content,
        node.lastUpdate,
        node.subtreeDataId ?? "", //TODO post-mig non-null
        node.parentIds,
        node.childrenIds,
        node.SubtreeData,
        // node.Parents,
        // node.Children,
    );

    return dataNode;
}

/**
 * NOTE: the lastFetched property returned by this function actually represents the lastUpdate from the server
 */
// export function DataNodeDTOToLocalDBDataNode(node: DataNodeDTO): LocalDBDataNode {
//     return {
//         ...node,
//         lastFetched: node.lastUpdate,
//     }
// }

export function localDBDataNodeToDataNodeDTO(node: LocalDBDataNode): DataNodeDTO {
    return {
        ...node,
        lastUpdate: new Date(0, 0), // TODO should this return the last fetched time, or leave as 0?
        parentIds: node.parentIds ?? [],
        childrenIds: node.childrenIds ?? [],

        tags: [] //TODO Remove post-migration
    }
}

export async function dataNodeToLocalDBDataNode(node: DataNode<any>): Promise<LocalDBDataNode> {
    return {
        ...node,
    }
}