import { Navigate, Route, Routes } from 'react-router-dom';
import NavBar from './Components/TopNavBar';
import { NodeViewer } from './Pages/NodeViewer';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend'
import BottomNavBar from './Components/BottomNavBar';
import Planner from './Pages/Planner/Planner';
import { DataNodesProvider, OnlineStatusProvider, DebugModeProvider } from './Logic_Client';

import './scss/global.scss'
import Dashboard from './Pages/Dashboard';
import { ModalProvider } from './Logic_Client/hooks/useModal';

function App() {
  // TODO need to handle links that point to public nodes, even if not logged in...
  return (
    <DndProvider backend={TouchBackend} >
      <OnlineStatusProvider>
        <DebugModeProvider>
          <DataNodesProvider>
            <ModalProvider>
              <NavBar />
              <div id="content">
                <Routes>
                  <Route path="home" element={<Dashboard />} />
                  <Route path="data/:id" element={<NodeViewer />} />
                  <Route path="planner" element={<Planner />} />
                  <Route path="*" element={<Navigate to='home' />} />
                </Routes>
              </div>
              <BottomNavBar />
            </ModalProvider>
          </DataNodesProvider>
        </DebugModeProvider>
      </OnlineStatusProvider>
    </DndProvider >
  );
}

export default App;
