import React, { useEffect, useState } from 'react'
import { ConnectDragSource, useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import RoleElement from './RoleElement';

interface ReorderableListProps<T> {
    children: React.ReactElement<ReorderableListItemProps>[],
    onOrderChange: (newIndexOrder: number[]) => void,
    itemType?: string,
    moveTriggerThreshold?: number,
    className?: string,
    reverse?: boolean
}

export interface ReorderableListItemProps {
    dragHandle?: ConnectDragSource,
}

export default function ReorderableList<T>({ children, itemType, onOrderChange, moveTriggerThreshold = 0.7, className, reverse }: ReorderableListProps<T>) {
    const [indexOrder, setIndexOrder] = useState<number[]>(Array.from(Array(children.length).keys()));
    useEffect(() => {
        setIndexOrder(children.map((x, i) => i));
    }, [children])

    const onItemMove = (draggedId: number, targetId: number) => {
        if (indexOrder.length === 0) return;
        const dragInd = indexOrder.indexOf(draggedId);
        const targInd = indexOrder.indexOf(targetId);

        const spliced = update(indexOrder, {
            $splice: [
                [dragInd, 1],
                [targInd, 0, draggedId],
            ]
        })
        setIndexOrder(spliced);

    }

    const onItemDropped = () => {
        // TODO if order changed
        onOrderChange(indexOrder)
    }

    const list = indexOrder.map((id, index) => {
        const node = children[id];
        if (node) {
            return <DraggableListItem
                key={id}
                id={id}
                index={index}
                view={node}
                itemType={itemType}
                onItemMove={onItemMove}
                onItemDropped={onItemDropped} />
        }
        else {
            return null
        }
    })

    if (reverse)
        list.reverse();

    return (
        <ol className={`reorderable-list ${className}`}>
            {list}
        </ol>
    )
}


interface DraggableListItemProps {
    id: number,
    index: number,
    view: React.ReactElement<ReorderableListItemProps>,
    itemType?: string,
    onItemMove: (dragged: number, target: number) => void,
    onItemDropped: () => void
}

const DraggableListItem = ({ id, view, itemType, onItemMove, onItemDropped }: DraggableListItemProps) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: itemType ?? "undefined",
        item: { id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            onItemDropped();
        }
    }), [id, onItemDropped]);

    const [, drop] = useDrop<{ id: number, index: number }, void, { isOver: boolean, canDrop: boolean }>(() => ({
        accept: itemType ?? "undefined",
        hover: (item, monitor) => {
            if (item.id === id) return;
            else {
                // TODO If past threshold
                onItemMove(item.id, id)
            }
        },
    }), [id, onItemMove])

    view = React.cloneElement(view, { dragHandle: drag });

    // TODO how does drop work with previews?
    return (
        <li className={`reorderable-list-item ${isDragging ? "dragging" : ""}`} ref={node => preview(drop(node))}>
            {view}
        </li>
    )
}