class TaskData {
    content: any;
    description?: string;
    completion: number;
    deadline?: Date;
    priority: number;

    constructor(data: Partial<TaskData>) {
        this.completion = data.completion ?? 0;
        this.deadline = data.deadline;
        this.priority = data.priority ?? 0;
    }
}

export default TaskData