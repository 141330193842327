import { List } from '@mui/icons-material';
import Home from '@mui/icons-material/HomeOutlined'
import React, { HTMLAttributes, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDataNodes, useOnlineStatus } from '../Logic_Client';


const BottomNavBar: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const { setCurrentNode } = useDataNodes();
    const navigate = useNavigate();

    const handleButtonPress = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        switch (event.currentTarget.name) {
            case "home":
                setCurrentNode(null);
                break;
            case "tasklist":
                navigate("planner", { state: { subtreeId: window.location.pathname.split('data/')[1] } });
                break;
        }
    }

    return (
        <footer id="bottom-navbar">
            <button name="home" onClick={handleButtonPress}><Home /></button>
            <button name="tasklist" onClick={handleButtonPress}><List /></button>
        </footer>
    )
}


export default BottomNavBar;