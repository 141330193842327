import { useState } from 'react'
import { DataNode, NodeDataType, TaskData } from '../../../Logic_Client/DataModels';
import { NewNodeModalProps } from '../Generic/NewNodeModal';
import update from 'immutability-helper';
import { useDataNodes, useUser } from '../../../Logic_Client';
import { Alarm, Notes, TextFields } from '@mui/icons-material';

export default function NewTaskModal({ parentNode, onClose }: NewNodeModalProps) {
  const { user } = useUser();
  const { createNodes } = useDataNodes();
  const [formData, setFormData] = useState<Object>({ title: "", description: "" });
  const handleChange = evt => {
    const newState = update(formData, {
      $merge: { [evt.target.name]: evt.target.value }
    })
    setFormData(newState);
  }
  const handleSubmit = evt => {
    evt.preventDefault();
    if (!user) return;

    const title = evt.target.title.value;
    if (!title) return; //TODO provide user feedback
    const description = evt.target.description.value;
    // const deadline = new Date(evt.target.deadline.value);

    const data: TaskData = {
      content: null,
      priority: 0,
      completion: 0,
      // deadline
    }
    let newTask: Partial<DataNode<TaskData>>;

    if (parentNode) {
      // Create as child
      newTask = {
        id: Date.now().toString(),
        userId: user.id,
        title,
        data: data as any,
        dataType: NodeDataType.Task,
        parentIds: [parentNode.id],
        content: [{ type: 'paragraph', children: [{ text: description }] }]
      }
    } else {
      // Create as project
      newTask = {
        id: Date.now().toString(),
        userId: user.id,
        title,
        data: data as any,
        dataType: NodeDataType.Task,
        content: [{ type: 'paragraph', children: [{ text: description }] }]
      }
    }
    createNodes([
      newTask
    ])
    setFormData({})
    onClose?.();
  }

  return (
    <div className='new-task-modal simple-modal'>
      <h2 className='modal-title'>New Task</h2>

      <form onSubmit={handleSubmit} className='modal-content new-task-form'>
        {/* Title */}
        <TextFields className='icon' />
        <input type="text"
          name="title"
          className='input'
          value={formData['title'] ?? ""}
          onChange={handleChange}
          placeholder="Add a title"
          autoFocus={true}
        />

        {/* Description */}
        <Notes className='icon' />
        <input type="text"
          name="description"
          className='input'
          value={formData['description'] ?? ""}
          multiple
          onChange={handleChange}
          placeholder="Add a description"
        />

        {/* TODO Is Recurring */}

        {/* TODO Deadline */}
        {/* <Alarm />
        <input type="datetime-local"
          name="deadline"
          value={formData['deadline'] ?? ""}
          onChange={handleChange}
          placeholder="Set a deadline"
        /> */}

        {/* Submit button */}
        <button type="submit"
          className='submit'
        >
          Create
        </button>
      </form>
    </div>
  )
}
