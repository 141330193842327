import InheritanceMode from "./InheritanceMode";

export type PermissionType = "CREATE" | "READ" | "UPDATE" | "DELETE";
export type PermissionAccessMode = "PUBLIC" | "ROLE" | "USER";

export interface NodePermission {
    permission: PermissionType
    access: PermissionAccessMode
    id?: string
    inheritance?: InheritanceMode
}

export function encodePermission(permission: PermissionType, accessMode: PermissionAccessMode, id?: string, inheritanceMode: InheritanceMode = InheritanceMode.unknown) {
    return `${inheritanceMode}${permission}├${accessMode}${id ? "├" + id : ""}`;
}
export function encodeNodePermission(permission: NodePermission) {
    return `${permission.inheritance ?? "?"}${permission.permission}├${permission.access}${permission.id ? "├" + permission.id : ""}`;
}

export function decodePermission(encodedPermission: string): NodePermission {
    return {
        inheritance: getPermissionInheritance(encodedPermission),
        permission: getPermissionType(encodedPermission),
        access: getPermissionAccessMode(encodedPermission),
        id: getPermissionId(encodedPermission)
    }
}

export function getPermissionInheritance(encodedPermission: string): InheritanceMode {
    return encodedPermission[0] as InheritanceMode;
}
export function getPermissionType(encodedPermission: string): PermissionType {
    const split = encodedPermission.split('├');
    return split[0].substring(1) as PermissionType;
}
export function getPermissionAccessMode(encodedPermission: string): PermissionAccessMode {
    const split = encodedPermission.split('├');
    return split[1] as PermissionAccessMode;

}
export function getPermissionId(encodedPermission: string): string | undefined {
    const split = encodedPermission.split('├');
    if (split.length === 2) return undefined;
    else return split[2];

}


export function getPublicPermissions(permissions: string[]): string[] {
    return permissions.filter(x => getPermissionAccessMode(x) === "PUBLIC");
}
export function getUserPermissions(permissions: string[]): string[] {
    return permissions.filter(x => getPermissionAccessMode(x) === "USER");
}
export function getRolePermissions(permissions: string[]): string[] {
    return permissions.filter(x => getPermissionAccessMode(x) === "ROLE");
}
export function getPermissionsForUser(userId: string, permissions: string[]): string[] {
    return permissions.filter(x => {
        const userMode = getPermissionAccessMode(x) === "USER"
        const isUser = getPermissionId(x) === userId
        return userMode && isUser;
    });
}

export function getUserIds(permissions: string[]): string[] {
    return permissions.filter(x => getPermissionAccessMode(x) === "USER").map(x => getPermissionId(x)!);
}
export function getRoleIds(permissions: string[]): string[] {
    return permissions.filter(x => getPermissionAccessMode(x) === "ROLE").map(x => getPermissionId(x)!);
}