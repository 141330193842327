import TaskProjectView from './TASK/TaskProjectView'
import { DataNodesHook } from "../../Logic_Client";
import { NodeDataType } from '../../Logic_Client/DataModels';

export type ProjectViewProps = DataNodesHook & React.HTMLAttributes<HTMLDivElement>

export function GetProjectView(dataType: string): React.FC<ProjectViewProps> {
    switch (dataType) {
        case NodeDataType.Task: return TaskProjectView;
        default: throw new Error(`${dataType} does not have a project view for display`)
    }
}