import { createContext, useContext, useEffect, useState } from "react"


export const OnlineStatusContext = createContext<boolean>(false);

export const useOnlineStatus = () => useContext(OnlineStatusContext);

export function OnlineStatusProvider({ children, }) {
    const [online, setOnline] = useState(window.navigator.onLine);

    useEffect(() => {
        const onOnline = () => {
            navigator.serviceWorker.controller?.postMessage({ msg: "online" });
            console.log("ONLINE");

            setOnline(true);
        }
        const onOffline = () => {
            navigator.serviceWorker.controller?.postMessage({ msg: "offline" });
            console.log("OFFLINE");
            setOnline(false);
        }
        navigator.serviceWorker.ready.then(() => {
            window.addEventListener('online', onOnline);
            window.addEventListener('offline', onOffline);
        });

        return () => {
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
        }
    }, [])

    return (
        <OnlineStatusContext.Provider value={online}>
            {children}
        </OnlineStatusContext.Provider>
    )
}
