import { Role, User as UserData } from "prisma/prisma-client";
import { User as AuthUser } from 'firebase/auth';
import axios from "axios";
import { GetUserRolesRequest } from "../../Logic_Server/RequestInterfaces/RoleRequestInterfaces";

const GET_ROLE_ENDPOINT = "";


class User implements UserData {
    // TODO after db-migration
    displayName: string | null; //TODO non-null
    roles = []


    authUser: AuthUser;
    id: string;

    get displayImageURI(): string | null {
        return this._displayImageURI ?? this.authUser.photoURL;
    }
    _displayImageURI: string | null;

    get email(): string | null {
        return this._email ?? this.authUser.email;
    }
    _email: string | null;

    productRoles: string[];
    public get userRoles(): Promise<Role[]> {
        if (this._userRoles) {
            return Promise.resolve(this._userRoles);
        } else {
            return new Promise(async (res, rej) => {
                const req: GetUserRolesRequest = {
                    method: "POST",
                    body: {
                        method: "GET_USER",
                        authToken: await this.token,
                        userId: this.id,
                    }
                }
                return axios.post(GET_ROLE_ENDPOINT, req.body)
            })
        }
    }
    private _userRoles;

    public get token(): Promise<string> {
        return this.authUser.getIdToken();
    }

    constructor(authUser: AuthUser, userData: UserData) {
        this.authUser = authUser;
        this.id = userData.id;

        this.displayName = userData.displayName;
        this._displayImageURI = userData.displayImageURI;
        this._email = userData.email;

        this.productRoles = userData.productRoles;
    }

    /**
     * Returns whether or not a user has the given USER-DEFINED role. This could be anything.
     * Not to be confused with product roles
     */
    async hasRole(name: string): Promise<boolean> {
        const roles = await this.userRoles;
        return roles.some(x => x.displayName === name || x.id === name);
    }

    /**
    * Returns whether or not a user has the given USER-DEFINED role, compared against id.
    * This is more secure than comparing display name, so it should be preferred if the role id is available.
    */
    async hasRoleID(id: string): Promise<boolean> {
        const roles = await this.userRoles;
        return roles.some(x => x.id === id);
    }

    /**
     * Returns whether or not a user has the given PRODUCT role. This denotes admin, developer, and payment status.
     * Not to be confused with roles defined by customers
     */
    hasProductRole(name: string): boolean {
        return this.productRoles?.includes(name) ?? false;
    }
}
export default User