import { useEffect, useState } from 'react'
import { ArrowRight, Search } from '@mui/icons-material';
import PulloutMenu, { PulloutProps } from './Overlays/Pullout';
import { useDataNodes, useUser } from '../Logic_Client';
import { DataNode, DTOTools } from '../Logic_Client/DataModels';
import axios from 'axios';
import { GetNodesByUserPermission, GetNodesByUser, GetNodesResponse } from '../Logic_Server/RequestInterfaces/NodeRequestInterfaces';
import NodeSearch from './NodeSearch';
import GenericNodePreview from './DataNodes/Generic/GenericNodePreview';

interface NodeSearchPulloutProps {
    onClose: () => void;
}

export default function NodeSearchPullout({ placement, open, onClose }: NodeSearchPulloutProps & PulloutProps) {
    const { setCurrentNode } = useDataNodes();
    const [roots, setRoots] = useState<DataNode<any>[]>([]);
    const { user } = useUser();

    useEffect(() => {
        if (!open) return;

        async function fetchSharedNodes() {
            if (!open || !user) return;
            const token = await user.token;

            // Request all nodes that this user may have viewing permissions for
            const nodeReq: GetNodesByUserPermission = {
                method: 'GET',
                query: {
                    authToken: token
                },
            }

            const res: GetNodesResponse = (await axios.get("/api/nodes/shared", {
                params: nodeReq.query
            })).data

            setRoots(res.found.map(x => DTOTools.DataNodeDTOToObject(x)));
            console.log(res);
        }

        fetchSharedNodes();
    }, [open, user, setRoots])


    const handleClose = () => {
        onClose();
    }

    const handleNodeClicked = (node: DataNode<any>) => {
        setCurrentNode(node.id);
        handleClose();
    }

    return (
        <PulloutMenu id="navigation-pullout"
            placement={placement}
            open={open}
            onClose={handleClose}
            cssSize="80vw"
        >
            <NodeSearch />

            {/* TODO Temp */}
            <h1>
                Shared with me:
            </h1>

            <hr />

            <div className="node-list">
                {
                    roots.map(x => {
                        return (
                            <GenericNodePreview
                                node={x}
                                onClick={handleNodeClicked}
                            />
                        )
                    })
                }
            </div>
        </PulloutMenu >
    )
}




