import { useCallback, useRef, useState } from 'react'
import { AddCircle, AddCircleOutline, Circle, Code, FormatBold, FormatItalic, FormatQuote, FormatStrikethrough, FormatUnderlined, List } from '@mui/icons-material'
import { Editor } from 'slate'
import { useDataNodes } from '../../Logic_Client';
import { toggleMark, toggleType, insertNode, Mark, CustomElement } from '../SlateJs/FuckEncapsulation';


interface NodeEditorControlsProps {
    editor: Editor
}

export default function ContentContextBar({ editor }: NodeEditorControlsProps) {
    const { currentNode } = useDataNodes();

    const subnodeControl = useRef<HTMLDivElement>(null);
    const [addNode, setAddNode] = useState(false);
    const [addNodeSearchInput, setAddNodeSearchInput] = useState("");
    const subnodeSearchInput = useRef<HTMLInputElement>(null);

    const toggleM = useCallback((evt, mark: Mark) => {
        evt.preventDefault();
        toggleMark(editor, mark);
    }, [])

    const insert = useCallback((evt, node: CustomElement) => {
        evt.preventDefault();
        insertNode(editor, node);
    }, [])

    const toggleT = useCallback((evt, type: Pick<CustomElement, 'type'>) => {
        evt.preventDefault();
        toggleType(editor, type);
    }, [])


    return (
        <div id="content-context-bar">
            {/* <div
                ref={subnodeControl}
                className={`add-data-pullout ${addNode ? "open" : "closed"}`}
            >
                <input type='text'
                    className='search-input'
                    ref={subnodeSearchInput}
                    value={addNodeSearchInput}
                    onChange={evt => setAddNodeSearchInput(evt.currentTarget.value)}
                    onBlur={evt => { setAddNode(false); setAddNodeSearchInput(""); }}
                />
                <button
                    className='add-btn btn-text'
                    onClick={evt => {
                        setAddNode(prev => {
                            if (!prev) {
                                subnodeSearchInput.current?.focus();
                            }
                            return !prev;
                        });
                    }}
                >
                    <AddCircleOutline />
                </button>
            </div> */}
            <div className='formatting-buttons-container'>
                <button className='format-btn btn-outline' onMouseDown={evt => toggleM(evt, 'bold')}><FormatBold /></button>
                <button className='format-btn btn-outline' onMouseDown={evt => toggleM(evt, 'italic')}><FormatItalic /></button>
                <button className='format-btn btn-outline' onMouseDown={evt => toggleM(evt, 'underline')}><FormatUnderlined /></button>
                <button className='format-btn btn-outline' onMouseDown={evt => toggleM(evt, 'linethrough')}><FormatStrikethrough /></button>
                {/* <button onMouseDown={evt => toggleT(evt, { type: 'quote' })}><FormatQuote /></button>
                <button onMouseDown={evt => toggleT(evt, { type: 'code' })}><Code /></button> */}
                {/* <button className='format-btn btn-outline' onMouseDown={evt => insert(evt, { type: 'tasklist', parentId: currentNode!.id, nodeIds: currentNode!.childrenIds, children: [{ text: '' }], })}><List /></button> */}
                {/* <button onMouseDown={evt => insert(evt, { type: 'editable-void', children: [{ text: '' }], })}><Circle /></button> */}
            </div>
        </div >
    )
}
