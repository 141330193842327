import PulloutMenu from '../Components/Overlays/Pullout';
import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, logout, auth, signInWithEmail, signUpWithEmail } from '../firebaseAuth';

export default function LoginAndRegister() {
    const [loginFormOpen, setLoginFormOpen] = useState(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [user, loading, authErr] = useAuthState(auth);
    const navigate = useNavigate();
    const login = () => {
        signInWithEmail(email, password)
    }
    const register = () => {
        signUpWithEmail(email, password).then(res => console.log(res)).catch(res => console.error(res));
    };

    useEffect(() => {
        if (!loading && user) {
            navigate('/app', { replace: true });
        }
    }, [user, loading])
    return (
        <div className="login-bg">
            <div className="login-content">
                {/* <h1 id='brand'>Wayfinder</h1> */}
                <h3 id='ready'>
                    ready to find
                </h3>
                <h2 id='your-way'>
                    YOUR WAY?
                </h2>
                <button className="login-btn" onClick={signInWithGoogle}>
                    Login with Google
                </button>
                <button className="login-btn" onClick={() => { setLoginFormOpen(!loginFormOpen) }}>
                    Login with Email
                </button>
                {/* <div className='disclaimer'>
                    don't worry, more options are on the way
                </div> */}
            </div>
            <PulloutMenu
                placement='bottom'
                open={loginFormOpen}
                onClose={() => setLoginFormOpen(false)}
                cssSize="30vh"
                className='email-login'
            >
                <input type="text"
                    className="input-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                />
                <input type="password"
                    className="input-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <button
                    className="login__btn"
                    onClick={login}
                >
                    Login
                </button>
            </PulloutMenu>
        </div>
    )
}
