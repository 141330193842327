import { Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Item, Menu, useContextMenu } from 'react-contexify';
import { useDataNodes, useUser } from '../../../Logic_Client';
import { DataNode, TaskData } from '../../../Logic_Client/DataModels'
import useDebounced from '../../../Logic_Client/hooks/useDebounced';
import GenericNodePreview from './GenericNodePreview';
import NodeAncestorNavButton from './NodeAncestorNavButton';

interface MoveNodeModalProps {
    toMove: DataNode<any>[],
    currentParent: DataNode<any>,
    onClose?: (result: "cancelled" | "moved") => void
}

export default function MoveNodeModal({ toMove, currentParent, onClose }: MoveNodeModalProps) {
    const { searchNodes, getNodes, getRoots, updateNodes } = useDataNodes();

    const [targetNode, setTargetNode] = useState<DataNode<any> | null>(currentParent);

    const [searchQuery, setSearchQuery] = useState("");
    const [list, setList] = useState<DataNode<any>[]>([]);

    const { show } = useContextMenu();
    const searchDebounced = useDebounced((query: string) => searchNodes(query), 500, [])
    const [searchResults, setSearchResults] = useState<DataNode<any>[] | null>([]);

    const { user } = useUser();

    useEffect(() => {
        async function loadList() {
            if (!user) return;

            if (targetNode) {
                await targetNode.fetchRelations(await user?.token);
                setList(targetNode.children!);
            } else {
                const roots = await getRoots();
                setList(roots);
            }
        }

        setList([]);
        loadList();
    }, [targetNode])

    const changeRoot = (id?: string) => {
        if (id) {
            getNodes([id]).then(nodes => {
                const node = nodes[0];
                setTargetNode(node);
            })
        } else {
            setTargetNode(null);
        }
    }

    const handleSearchQueryChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query === "") {
            setSearchResults([]);
        } else {
            const box = event.currentTarget.getBoundingClientRect();
            const x = box.x;
            const y = box.y + box.height;
            show({ id: "search-dropdown", event: event as any, position: { x, y } });
            const results = await searchDebounced(query)
            if (results.length > 0)
                setSearchResults(results);
            else setSearchResults(null);
        }
    }

    const handleMove = (evt) => {
        const parentsToAdd = targetNode ? [targetNode.id] : undefined;
        const parentsToRemove = [toMove[0].parentIds[0]];
        updateNodes([{
            id: toMove[0].id,
            parentsToAdd,
            parentsToRemove
        }]);
        onClose?.("moved");
    }

    return (
        <div className='move-node-modal simple-modal'>
            <div className='modal-title'>
                <h2>Move '{toMove[0].title}'</h2>
                <h4>To {targetNode?.title ?? "Home"}:</h4>
            </div>

            <div className="modal-content">
                {targetNode &&
                    <NodeAncestorNavButton parents={targetNode?.parents ?? []} onClick={(evt, target) => changeRoot(target)} />
                }
                <div className='move-node-list-options'>
                    {
                        list.map(li =>
                            <GenericNodePreview key={li.id} node={li} onClick={(node) => setTargetNode(node)} />
                        )
                    }
                </div>
                {/* <div className="node-search-bar" >
                    <input type="text"
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                        placeholder="Search by title..."
                    />
                    <Search />
                </div> */}
            </div>

            <button className='modal-cancel btn-cancel' onClick={() => onClose?.("cancelled")}>
                Cancel
            </button>

            <button className='modal-confirm btn-confirm' onClick={handleMove}>
                Move Here
            </button>

            <Menu id='search-dropdown'>
                {searchResults ?
                    searchResults.map(x =>
                        <Item>
                            {x.title}
                        </Item >
                    ) :
                    <Item>
                        "No results found"
                    </Item>
                }
            </Menu>
        </div>
    )
}
