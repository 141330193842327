import { DataNode, TaskData } from '../../../Logic_Client/DataModels'
import React, { useEffect, useState } from 'react'
import { DataEditorProps } from '../GetTypeView'
import update from 'immutability-helper';

export default function DataEditor({ node, onChange, disabled, className }: DataEditorProps<TaskData>) {
    const [clone, setClone] = useState(node.clone())
    useEffect(() => {
        setClone(node.clone())
    }, [node])


    const onDataChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const name = evt.target.name;
        const value =
            name === "completion" ? (evt.target.checked ? 1 : 0)
                : name === "deadline" ? new Date(evt.target.value)
                    : evt.target.value

        // If the data has actually changed
        if (clone.data[name] !== value) {
            const newNode = update(clone, {
                data: {
                    [name]: { $set: value }
                }
            })
            setClone(newNode);
            onChange?.(newNode);
        }
    }
    const dateTime = clone.data.deadline ? new Date(clone.data.deadline).toISOString().substring(0, 16) : "";
    return (
        <div className={`${className} taskdata-editor`}>
            <label
                htmlFor='completion'
                className="taskdata-editor__label"
            >
                Completed
            </label>
            < input
                type='checkbox'
                disabled={disabled}
                className="taskdata-editor__completion-checkbox"
                name="completion"
                checked={clone.data.completion === 1}
                onChange={onDataChange}
            />

            {/* <label
                htmlFor='completion'
                className="taskdata-editor__label"
            >
                Deadline
            </label>
            <input type='datetime-local'
                min={new Date().toISOString().substring(0, 16)}
                disabled={disabled}
                className="taskdata-editor__datetime-control"
                name="deadline"
                defaultValue={dateTime}
                onBlur={onDataChange}
            // onChange={onDataChange}
            /> */}
        </div>
    )
}
