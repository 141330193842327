import React, { useState } from 'react';


export default function FAQ() {
    const [show, setShow] = useState({});
    const [alt, setAlt] = useState({});

    const setOpen = (name) => {
        setShow({ ...show, [name]: !show[name] });
        setAlt({ ...alt, [name]: false });
    }

    const toggleAlt = (name) => {
        setAlt({ ...alt, [name]: !alt[name] });
    }

    return (
        <div className='FAQ' style={{ display: 'flex', flexDirection: 'column' }}>
            <h1>FAQ</h1>

            {show["elevator"] ?
                <div className="qa-box">
                    <h2 onClick={() => setOpen("elevator")}>
                        What is Wayfinder?
                    </h2>
                    <div className="qa-alt-toggle" onClick={() => toggleAlt("elevator")}>
                        {alt["elevator"] ?
                            <p>
                                Another hair-brained get-rich-quick scheme. It’s the next big thing since NFTs!
                            </p> : <p>
                                We’ve all had big dreams that somehow get lost to time. Whether it be welcomed distractions, or disorienting transitions, things outside of our control can derail our greatest ambitions without our permission. Wayfinder combines the best of self-management philosophies with modern psychology to make sure your priorities stay prioritized, and consistently developing.
                            </p>
                        }
                    </div>
                </div>
                :
                <h2 onClick={() => setOpen("elevator")}>
                    What is Wayfinder?
                </h2>
            }


            {show["cost"] ?
                <div className="qa-box" >
                    <h2 onClick={() => setOpen("cost")}>
                        Will Wayfinder always be free?
                    </h2>
                    <div className="qa-alt-toggle" onClick={() => toggleAlt("cost")}>
                        {alt["cost"] ?
                            <p>
                                Gotta eat bro…
                            </p> : <p>
                                We believe Wayfinder has the potential to help people function in a tech world we weren't designed for, and as such we want to provide as much functionality to as many people for free as we possibly can. All features for individual use will be provided for free for ever, but subcriptions for collaboration tools and for corporations will eventually be available.
                            </p>
                        }
                    </div>
                </div>
                :

                <h2 onClick={() => setOpen("cost")}>
                    Will Wayfinder always be free?
                </h2>
            }


            {show["roadmap"] ?
                <div className="qa-box">
                    <h2 onClick={() => setOpen("roadmap")}>
                        What’s next?
                    </h2>
                    <div className="qa-alt-toggle" onClick={() => toggleAlt("roadmap")}>
                        {alt["roadmap"] ?
                            <p>
                                World Domination. Then maybe a trip to Antarctica. But that’ll probably take a couple weeks, and who plans that far ahead anyway?
                            </p> : <p>
                                Too many things to put in an FAQ. Checkout our <a>roadmap</a>.
                            </p>
                        }
                    </div>
                </div>
                :
                <h2 onClick={() => setOpen("roadmap")}>
                    What’s next?
                </h2>
            }


            {show["involve"] ?
                <div className="qa-box" >
                    <h2 onClick={() => setOpen("involve")}>
                        Can I get involved?
                    </h2>
                    <div className="qa-alt-toggle" onClick={() => toggleAlt("involve")}>
                        {alt["involve"] ?
                            <>
                                <p>
                                    GIMME YOUR MONEY!
                                </p>
                                {/* TODO add paypal link */}
                                <form className='paypal-link' action="https://www.paypal.com/donate" method="post" target="_blank" onClick={e => e.stopPropagation()}>
                                    <input type="hidden" name="business" value="7B7T3HXCDZ9CW" />
                                    <input type="hidden" name="no_recurring" value="0" />
                                    <input type="hidden" name="currency_code" value="USD" />
                                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                    <img alt="" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                                </form>

                            </> : <>
                                {/* TODO add paypal link */}
                                <p>
                                    Provide feedback! This early in development, the most valuable thing you can give is your ideas and insights. Once the alpha is finished, or the project receives enough funding, we’ll start accepting applications. In the meantime, paypal?
                                </p>

                                <form className='paypal-link' action="https://www.paypal.com/donate" method="post" target="_blank" onClick={e => e.stopPropagation()}>
                                    <input type="hidden" name="business" value="7B7T3HXCDZ9CW" />
                                    <input type="hidden" name="no_recurring" value="0" />
                                    <input type="hidden" name="currency_code" value="USD" />
                                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                    <img alt="" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                                </form>
                            </>
                        }
                    </div>
                </div>
                :
                <h2 onClick={() => setOpen("involve")}>
                    Can I get involved?
                </h2>
            }


            {show["visuals"] ?
                <div className="qa-box" >
                    <h2 onClick={() => setOpen("visuals")}>
                        Why is your site ugly?
                    </h2>
                    <div className="qa-alt-toggle" onClick={() => toggleAlt("visuals")}>
                        {alt["visuals"] ?
                            <p>
                                I’m trying my hardest, alright?? :,(
                            </p> : <p>
                                Priorities. Once the core functionality is implemented, or a team is built, there will be a project wide rebranding, and UI polishing. Just hang in there ;)
                            </p>
                        }
                    </div>
                </div>
                :

                <h2 onClick={() => setOpen("visuals")}>
                    Why is your site ugly?
                </h2>
            }
        </div >
    )
}