import { Clear } from '@mui/icons-material';

export default function BubbleText({ id, children, error, image, onDelete }: { id: string, children: React.ReactNode, image?: string | React.ReactNode, error?: { msg: string }, onDelete?: (key: string) => void }) {
    const beforeImage = typeof image === 'string' ? <img src={image} /> : image ?? null;

    return (
        <div className={`name-bubble ${error ? "error" : ""}`}>
            {beforeImage}
            <span className='name'>
                {children}
            </span>
            {onDelete && <span className='remove-icon' onClick={() => { onDelete?.(id); }}>
                <Clear />
            </span>}

        </div>
    )
}