import React, { useState } from 'react'
import BubbleText from './BubbleText';

type TagListInputFieldProps = {
    items: string[],
    allowDuplicates?: boolean,
    errors?: Map<string, { msg: string }>,
    onAdd?: (newItem: string) => void,
    onRemove?: (deadItem: string) => void,
    onListChange?: (listItems: string[]) => void,
    onInputChange?: (input: string) => void
}

const TagListInputField: React.FC<{ className?: string } & TagListInputFieldProps> = ({ items, onAdd, onRemove, onListChange, onInputChange, allowDuplicates, className, errors }) => {
    const [input, setInput] = useState("");
    const clonedItems = Array.from(items)

    const handleInputChangeEvent = (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault();
        const lastChar = evt.target.value[evt.target.value.length - 1];

        switch (lastChar) {
            case ",":
            case " ":
                if (input) {
                    addToList(input);
                }
                break;
            default:
                setInput(evt.target.value.replace(' ', ''))
                onInputChange?.(input);
        }

    }

    const handleKeyDownEvent = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        switch (evt.key) {
            case 'Enter': {
                if (input) {
                    addToList(input);
                }
                break;
            }
            case 'Backspace': {
                // TODO Would be nice if the first press selected the bubble, and the second press deleted it...
                if (!input && !evt.repeat) {
                    evt.preventDefault();
                    const item = clonedItems[clonedItems.length - 1];
                    setInput(item);
                    removeFromList(item);
                }
            }
        }
    }

    const addToList = (newItem: string) => {
        if (allowDuplicates || !clonedItems.some(x => x === newItem)) {
            clonedItems.push(newItem);
            setInput("");
            onAdd?.(newItem);
            onListChange?.(clonedItems);
        }
    }

    const removeFromList = (deadItem: string) => {
        clonedItems.splice(clonedItems.findIndex(x => x === deadItem), 1)
        onRemove?.(deadItem);
        onListChange?.(clonedItems);
    }

    return (
        <form className={className} onSubmit={evt => { evt.preventDefault(); }}>
            {clonedItems.map(x =>
                <BubbleText
                    id={x}
                    onDelete={name => { removeFromList(name); }}
                    error={errors?.has(x) ? errors.get(x) : undefined}
                >
                    {x}
                </BubbleText>
            )}
            <input type='text'
                name='userInput'
                value={input}
                inputMode="email"
                onKeyDown={handleKeyDownEvent}
                onChange={handleInputChangeEvent}
                placeholder={`${clonedItems.length === 0 ? 'jerry@smith.com' : ''}`}
            />
        </form>
    )
}

export default TagListInputField