import React, { useEffect, useState } from 'react'
import { Search } from '@mui/icons-material'
import { useDataNodes } from '../Logic_Client';
import { DataNode } from '../Logic_Client/DataModels';
import useDebounced from '../Logic_Client/hooks/useDebounced';
import GenericNodePreview from './DataNodes/Generic/GenericNodePreview';

export default function NodeSearch() {
    const { setCurrentNode, searchNodes } = useDataNodes();
    const [searchQuery, setSearchQuery] = useState("");
    const [list, setList] = useState<DataNode<any>[]>([]);

    const search = useDebounced((query: string) => searchNodes(query), 500, [searchQuery]);

    useEffect(() => {
        const exec = async function () {
            const results = await search(searchQuery);
            console.log(results);
            if (results)
                setList(results);
        }
        exec();
    }, [searchQuery])


    const handleNodeClicked = (node: DataNode<any>) => {
        setCurrentNode(node.id);
    }

    return (
        <>
            <div className="node-search-header">
                <div className="node-search-bar" >
                    <Search />
                    <input type="text"
                        value={searchQuery}
                        onChange={evt => setSearchQuery(evt.target.value)}
                        placeholder="Search by title..."
                    />
                </div>
            </div>

            <div className="node-list">
                {
                    list.map(x => {
                        return (
                            <GenericNodePreview
                                node={x}
                                onClick={handleNodeClicked}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}
