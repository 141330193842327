import { ArrowRight } from "@mui/icons-material";
import { DataNode } from "../../../Logic_Client/DataModels";

interface GenericNodePreviewProps {
    node: DataNode<any>,
    onClick: (node: DataNode<any>) => void
}
export default function GenericNodePreview({ node, onClick }: GenericNodePreviewProps) {
    return (
        <div className='node-preview btn-outline' onClick={() => onClick(node)}>
            <span>
                {node.title}
            </span>
            <ArrowRight />
        </div>
    )
}