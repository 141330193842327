import axios from 'axios';
import { User } from '../DataModels'
import { GetUserRequest } from '../../Logic_Server/RequestInterfaces/UserRequestInterfaces';
import { createContext, useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, logout } from '../../firebaseAuth'
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext<{
    user: User | null,
    loading: boolean,
    error: Error | undefined,
    signout: () => void
}>({
    user: null,
    loading: true,
    error: undefined,
    signout: () => { }
});

/**
 * @returns UserData, LoggedIn, Loading, Errors
 */
export const useUser = (signedOutRedirectPath?: string) => {
    const state = useContext(UserContext);
    const navigate = useNavigate();
    if (signedOutRedirectPath) {
        if (!state.loading && state.user === null) {
            navigate(signedOutRedirectPath, { replace: true });
        }
    }
    return state;
}

export function UserProvider({ children }) {
    const [user, loading, error] = useAuthState(auth)
    const [userData, setData] = useState<User | null>();
    const navigate = useNavigate();

    useEffect(() => {
        if (userData) return;
        if (user) {
            user.getIdToken(true).then(token => {
                const req: GetUserRequest = {
                    method: "POST",
                    body: {
                        method: "GET",
                        authToken: token
                    }
                }
                axios.post('/api/users', req.body).then(async res => {
                    if (res.data.error) { console.error(res.data.error); return; }
                    setData(new User(user, res.data));
                }).catch(err => {
                    console.error(err.response)
                    setData(null);
                })
            })
        }
    }, [user, setData])

    const signout = () => {
        logout();
        setData(undefined);

        // This must be done because the user-state is not updated in time for the login page to read that the user logged out, so it sends them right back to the app!
        setTimeout(() => {
            navigate('/login');
        }, 10);
    }

    const data = {
        user: userData ?? null,
        loading: loading || !userData,
        error: error,
        signout
    }
    return (
        <UserContext.Provider value={data}>
            {children}
        </UserContext.Provider>
    )
}