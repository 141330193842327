import { ArrowRight } from '@mui/icons-material';
import { DataNode, TaskData } from '../../Logic_Client/DataModels';

type SubTaskViewProps = {
    dataNode: DataNode<TaskData>;
    onClicked?: (id: string) => void;
    onDataChange?: (node: DataNode<TaskData>, property: string, value: any) => void;
}

export default function SubtaskPlannerView({ dataNode, onDataChange, onClicked }: SubTaskViewProps) {
    const data = dataNode.data;

    return (
        <div className="todolist-item">
            <input type="checkbox"
                disabled={dataNode.id === "temp"}
                name="completion"
                checked={data.completion === 1}
                onChange={(evt) => {
                    onDataChange?.(dataNode, "completion", evt.target.checked ? 1 : 0)
                }} />

            <button className="selector btn-outline" onClick={() => onClicked?.(dataNode.id)}>
                {dataNode.title}
                <ArrowRight />
            </button>
        </div>
    )
}
