import React, { useEffect, useState } from 'react'
import Modal from '../../Overlays/Modal'
import { useUser } from '../../../Logic_Client'
import Subscriptions from '../../Subscriptions';
import { UserDTO } from '../../../Logic_Server/DTOs';
import { CircularProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import TagListInputField from './TagListInput';
import BubbleText from './BubbleText';
import { DataNode } from '../../../Logic_Client/DataModels';
import axios from 'axios';
import _ from 'lodash';
import update from 'immutability-helper'
import { decodePermission, encodeNodePermission, encodePermission, getPermissionId, getPermissionsForUser, getPermissionType, getPublicPermissions, getRoleIds, getUserIds, PermissionType } from '../../../Logic_Server/DTOs/NodePermissions'
import { GetUsersRequest, GetUsersResponse, SearchUsersRequest } from '../../../Logic_Server/RequestInterfaces/UserRequestInterfaces';
import { UpdateSubtreeDataRequest as UpdateSubtreeDataRequest } from '../../../Logic_Server/RequestInterfaces';

type SharingModalProps = {
    node: DataNode<any>,
    onClose: () => void,
}

const SharingModal: React.FC<SharingModalProps> = ({ node, onClose }) => {
    const { user } = useUser();
    const canShare = user?.hasProductRole("sharer") ?? false;

    const [permissions, setPermissions] = useState<string[] | null>(null);
    const [users, setUsers] = useState<Map<string, UserDTO>>(new Map())

    const [usersToAdd, setUsersToAdd] = useState<string[]>([])
    const [addErrors, setAddErrors] = useState<Map<string, { msg: string }>>(new Map())

    const [hasChanges, setHasChanges] = useState(false);


    // #region Effects

    useEffect(() => {
        if (!user) return;

        async function getPermissionsData() {
            node._permissions = undefined;
            await node.fetchSubtreeData(await user!.token);
            setPermissions(node.Permissions);

            const usersToFetch: string[] = []
            const token = await user!.token;
            // const roleIds = getRoleIds(permissions);
            // if (roleIds.length > 0) {
            //     const req: GetRolesRequest = {
            //         method: "POST",
            //         body: {
            //             method: "GET",
            //             authToken: token,
            //             roleIds
            //         }
            //     }
            //     await axios.post("/api/roles", req.body).then(res => {
            //         const data: GetRolesResponse = res.data;
            //         const rolePermissions = data.found.map(x => ({ role: x, permissions: node.permissions.getRolePermissions(x.id) })).filter(x => x.permissions.length > 0);
            //         const mapped = res.data.found.flatMap(x => x.Users.map(x => x.id))
            //         usersToFetch.push(...mapped);
            //     })
            // }

            const userIds = getUserIds(node.Permissions);
            if (userIds.length > 0) {
                const req: GetUsersRequest = {
                    method: "POST",
                    body: {
                        method: "GET",
                        authToken: token,
                        userIds: usersToFetch.concat(...userIds)
                    }
                }
                await axios.post("/api/users", req.body).then(res => {
                    const data: GetUsersResponse = res.data;
                    const clone = new Map(users)
                    data.found.forEach(user => {
                        clone.set(user.email!, user);
                    });
                    setUsers(clone);
                })
            }
        }

        getPermissionsData();
    }, [node, user, setUsers])


    useEffect(() => {
        if (!permissions) return;

        // setPermissions(node.Permissions);
        setUsersToAdd([]);
        setUsers(new Map());
    }, [])

    //#endregion


    const addCollaborator = async (email: string) => {
        if (!user || !permissions) return;

        setUsersToAdd([...usersToAdd, email]);
        if (email === user.email || email === user.displayName) {
            setAddErrors(old => update(old, {
                [email]: { $set: { msg: "You already have full control. No further action can be taken" } }
            }))
            return;
        }

        const req: SearchUsersRequest = {
            method: "POST",
            body: {
                method: "SEARCH",
                authToken: await user.token,
                emails: [email]
            }
        }
        axios.post("/api/users", req.body).then(response => {
            const res: GetUsersResponse = response.data
            if (res.found.length === 0) {
                setAddErrors(old => update(old, {
                    [email]: { $set: { msg: `No user with email or username ${email} found` } }
                }))
            } else {
                const updated = update(permissions, {
                    $apply: (v: string[]) => {
                        const clone = Array.from(v);
                        clone.push(encodePermission("READ", "USER", res.found[0].id));
                        return clone;
                    }
                })
                setPermissions(updated);
                setUsers(update(users, {
                    [res.found[0].email!]: { $set: res.found[0] }
                }))
                checkForChanges(updated);
            }
        })
    }

    const removeCollaborator = (email: string) => {
        if (!permissions) return;

        if (addErrors.has(email)) {
            setAddErrors(update(addErrors, { $remove: [email] }))
        }
        setUsersToAdd(usersToAdd.filter(x => x !== email));
        setUsers(update(users, {
            $remove: [email]
        }))

        const user = users.get(email);
        if (user) {
            const userPerms = getPermissionsForUser(user.id, permissions);
            const updated = update(permissions, {
                $apply: (v: string[]) => {
                    return v.filter(x => !userPerms.includes(x));
                }
            })
            setPermissions(updated);
            checkForChanges(updated);
        }
    }

    const updateCollaborator = (user: UserDTO, newPerms: PermissionType[]) => {
        if (!permissions) return;

        const userPerms = getPermissionsForUser(user.id, permissions).map(x => getPermissionType(x));
        // What's added? What's deleted
        const added = newPerms.filter(x => !userPerms.includes(x));
        const deleted = userPerms.filter(x => !newPerms.includes(x));
        const removed = permissions.filter(x => getPermissionId(x) !== user.id || !deleted.includes(getPermissionType(x)))
        const inserted = removed.concat(added.map(x => encodePermission(x, "USER", user.id)));
        const updated = update(permissions, {
            $set: inserted
        })
        setPermissions(updated)
        checkForChanges(updated);
    }

    const setPublicPermissions = (perms: PermissionType[]) => {
        if (!permissions) return;

        const added = perms.filter(x => !getPublicPermissions(permissions).map(x => getPermissionType(x)).includes(x)).map(x => encodePermission(x, "PUBLIC"));
        const deleted = getPublicPermissions(permissions).filter(x => !perms.includes(getPermissionType(x)));
        // const afterRemoval = deleted.length === 0 ?
        //     permissions.Permissions :
        //     permissions.Permissions.filter(x => !(x.accessMode.name === "PUBLIC" && deleted.includes(x)));
        // const afterInsertion = afterRemoval.concat(added.map(x => ({
        //     permission: x,
        //     accessMode: { name: "PUBLIC", id: undefined }
        // })))
        const updated = update(permissions, {
            $apply: (v: string[]) => {
                const clone = v.filter(x => !deleted.includes(x));
                added.forEach(x => clone.push(x));
                return clone;
            }
        })
        setPermissions(updated);
        checkForChanges(updated);
    }

    const checkForChanges = async (newPerms: string[]) => {
        const nodePerms = node.Permissions
        const added = newPerms.filter(x =>
            !nodePerms.includes(x)
        )
        const removed = nodePerms.filter(x =>
            !newPerms.includes(x)
        )

        if (added.length > 0 || removed.length > 0) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }
    const getChanges = () => {
        if (!permissions) return [[], []];

        const nodePerms = node.Permissions
        const added = permissions.filter(x =>
            !nodePerms.includes(x)
        )
        const removed = nodePerms.filter(x =>
            !permissions.includes(x)
        )

        return [
            added,
            removed
        ]
    }

    const pushChanges = async () => {
        if (!hasChanges) return;
        if (!permissions) return;
        if (!user) throw new Error("Cannot push changes while inauthenticated");
        const [add, remove] = getChanges();

        const req: UpdateSubtreeDataRequest = {
            method: "PATCH",
            body: {
                authToken: await user.token,
                subtreeRootId: node.id,
                // TODO this is a pain in the ass. Just implement setSubtreeData...
                permissionsToAdd: add.map(x => decodePermission(x)),
                permissionsToRemove: remove.map(x => decodePermission(x))
            }
        }
        axios.patch("/api/nodes/subtreeData", req.body).then(res => {
            onClose();
        });
        // TODO make loading circle appear
    }

    if (user && permissions) {
        if (canShare) {
            return (
                <div className='sharing-modal'>
                    <label>Add People</label>
                    <TagListInputField
                        className='user-searchbar'
                        items={usersToAdd}
                        onAdd={addCollaborator}
                        onRemove={removeCollaborator}
                        errors={addErrors}
                    />

                    <AccessViewPane usersWithAccess={users} permissions={permissions} onUserRemoved={removeCollaborator} onUserUpdated={updateCollaborator} />

                    <hr />

                    <PublicAccessPanel permissions={permissions} onPermissionsChange={perms => {
                        setPublicPermissions(perms);
                    }} />

                    {hasChanges &&
                        <button className='positive' onClick={pushChanges}>
                            Confirm Changes
                        </button>
                    }
                </div>
            )
        } else {
            return (
                <Subscriptions />
            )
        }
    } else {
        // TODO display loading/authenticating. We should never get to this point, but for the sake of completion...
        return (
            <CircularProgress />
        )
    }
}

function AccessViewPane({ usersWithAccess, permissions, onUserRemoved, onUserUpdated }: {
    usersWithAccess: Map<string, UserDTO>,
    permissions: string[],
    onUserRemoved?: (name: string) => void,
    onUserUpdated: (user: UserDTO, newPerms: PermissionType[]) => void
}) {

    return (usersWithAccess.size === 0 ? null :
        <>
            <label>People with Access</label>
            <div className='view-pane'>
                {
                    Array.from(usersWithAccess).map(([email, user]) => {
                        return (
                            <div key={email} className="permissions-editor">
                                <BubbleText id={email}
                                    onDelete={name => onUserRemoved?.(name)}
                                >
                                    {user.email}
                                </BubbleText>
                                <PermissionsDropDown
                                    onPermissionsChange={
                                        perms => onUserUpdated?.(
                                            user, perms
                                        )
                                    }
                                    permissionTypes={
                                        getPermissionsForUser(user.id, permissions).map(x => {
                                            return getPermissionType(x)
                                        })
                                    }
                                />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

function PublicAccessPanel({ permissions, onPermissionsChange }: { permissions: string[], onPermissionsChange: (perms: PermissionType[]) => void }) {
    const publicPerms = getPublicPermissions(permissions);

    return (
        <div className="public-ctrl">
            <input type="checkbox"
                checked={publicPerms.length > 0}
                onChange={() => {
                    publicPerms.length === 0 ?
                        onPermissionsChange?.(["READ"])
                        :
                        onPermissionsChange?.([])
                }}
            />
            <h4 className='public-ctrl__text' onClick={() => { }}>Public Access</h4>
            {publicPerms.length > 0 &&
                <>
                    <PermissionsDropDown permissionTypes={publicPerms.map(x => getPermissionType(x))} onPermissionsChange={onPermissionsChange} />
                    <h6 className='public-ctrl__tip'>Click this link to share with people!</h6>
                    <span className='public-ctrl__link'
                        onClick={() => {
                            navigator.clipboard.writeText(window.location.href);
                            alert(`Copied link address!`);
                        }
                        }
                    > {window.location.href}
                    </span >
                </>
            }
        </div>
    )
}

function PermissionsDropDown({ permissionTypes, onPermissionsChange }: { permissionTypes: PermissionType[], onPermissionsChange?: (perms: PermissionType[]) => void }) {

    const handleChange = (evt: SelectChangeEvent<string>/* React.ChangeEvent<HTMLSelectElement> */) => {
        switch (evt.target.value) {
            case "PRIVATE": {
                onPermissionsChange?.([]);
                break;
            }
            case "VIEW": {
                onPermissionsChange?.(['READ']);
                break;
            }
            case "EDIT": {
                onPermissionsChange?.(['CREATE', 'READ', 'UPDATE', 'DELETE']);
                break;
            }
        }
    }

    const getValue = () => {
        if (permissionTypes.length === 0) {
            return "PRIVATE";
        } else if (permissionTypes.length === 1) {
            if (permissionTypes[0] === "READ") {
                return "VIEW";
            }
        } else if (permissionTypes.length > 1) {
            return "EDIT";
        }
        return "ERROR";
    }

    return (
        <Select
            className='permissions-dropdown'
            value={getValue()}
            onChange={handleChange}>
            <MenuItem value="PRIVATE">
                No Access
            </MenuItem>
            <MenuItem value="VIEW">
                View
            </MenuItem>
            <MenuItem value="EDIT">
                Edit
            </MenuItem>
        </Select>

    )
}

export default SharingModal