import React, { HTMLAttributes, useState } from 'react';
import { AccountCircle, ArrowLeft, ArrowRight, BugReport, Close, Feedback, Menu, Warning } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import { useUser, useOnlineStatus, useDebugMode, LocalDB } from '../Logic_Client';
import { Checkbox } from '@mui/material';
import RoleElement from './RoleElement';
import { useNavigate } from 'react-router-dom';
import PulloutMenu from './Overlays/Pullout';
import NodeSearchPullout from './NavigationPullout';



const admin = {
    bug: "https://docs.google.com/spreadsheets/d/17BMMWz8gVJWLHZtkdRZs-sDtsfGngQh3slg1tUUgh_A/edit#gid=2037771938",
    feature: "https://docs.google.com/spreadsheets/d/1K-WtFSOVOiyeQAztbiCktWwypvF15nGTU-JnK2r-oa4/edit#gid=978461709"
}

const tester = {
    bug: "https://docs.google.com/forms/d/e/1FAIpQLSfG_C9I54vHhHgFM0xF2Je9fUgEGvmPDceNhSwOI_3oGU8SdA/viewform?usp=sf_link",
    feature: "https://docs.google.com/forms/d/e/1FAIpQLScP4Yz3kHHbCFVR4ogsTSB9_XJ_rVGPNuQcS71T4LsV0lSsmw/viewform?usp=sf_link"
}

const NavBar: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const [debug, setDebug] = useDebugMode();
    const { user, signout } = useUser();
    const isAdmin = user?.hasProductRole("admin");
    const online = useOnlineStatus();
    const navigate = useNavigate();
    const [nodeMenuOpen, setNodeMenuOpen] = useState(false);
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);

    // TODO it might be best to put bugs/feature stuff in a hidden menu. Less engagement, but these really shouldn't be accessed frequently
    return (
        <>
            <header id="top-navbar" className={online ? "" : "offline"}>
                <button id="top-navbar_menu-toggle" className='nav-button' onClick={() => { setNodeMenuOpen(!nodeMenuOpen); setAccountMenuOpen(false); }}>
                    <Menu />
                </button>

                {process.env.REACT_APP_DEPLOYMENT_MODE &&
                    <span className='disclaimer'>
                        {process.env.REACT_APP_DEPLOYMENT_MODE}
                    </span>
                }

                <button id="top-navbar_account-toggle" className="nav-button" onClick={() => { setAccountMenuOpen(!accountMenuOpen); setNodeMenuOpen(false); }}>
                    {user?.displayImageURI ? <img src={user.displayImageURI} /> :
                        <AccountCircle />
                    }
                </button>
            </header >

            <NodeSearchPullout
                placement='left'
                open={nodeMenuOpen}
                onClose={() => setNodeMenuOpen(false)}
            />

            <PulloutMenu id="account-menu"
                placement='right'
                open={accountMenuOpen}
                onClose={() => { setAccountMenuOpen(false) }}
                cssSize='80vw'
                className='container'
            >
                <h2>{user?.displayName}</h2>
                <button className="menu-button" onClick={() => { signout(); }}>
                    Logout
                    <LogoutIcon />
                </button>
                <button className="menu-button"
                    onClick={() => { window.open(isAdmin ? admin.bug : tester.bug) }}>
                    <span className="text">
                        {isAdmin ? "View Reports" : "Bug Report"}
                    </span>
                    <BugReport />
                </button>

                <button className="menu-button"
                    onClick={() => { window.open(isAdmin ? admin.feature : tester.feature) }}>
                    <span className="text">
                        {isAdmin ? "View Ideas" : "I Have an Idea!"}
                    </span>
                    <Feedback />
                </button>

                <RoleElement role="developer">
                    <button className='menu-button' onClick={evt => setDebug(!debug)}>
                        Show Debug Info
                        <Checkbox checked={debug} value={debug} disabled />
                    </button>
                </RoleElement>

                <RoleElement role='hater'>
                    <span>
                        YOU SUCK
                    </span>
                </RoleElement>

                <button className="menu-button"
                    onClick={() => {
                        navigate("/FAQ");
                    }}
                >
                    FAQ
                    <HelpIcon />
                </button>
            </PulloutMenu>
        </>
    );
}

export default NavBar;
