import React, { createContext, SetStateAction, useContext, useState } from 'react'

export const DebugModeContext = createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>([false, {} as React.Dispatch<React.SetStateAction<boolean>>]);
export const useDebugMode = () => useContext(DebugModeContext);

export function DebugModeProvider({ children }) {
    const [debug, setDebug] = useState(false)
    return (
        <DebugModeContext.Provider value={[debug, setDebug]}>
            {children}
        </DebugModeContext.Provider>
    )
}