import React, { useRef } from 'react'
import { createPortal } from 'react-dom';

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode,
    onClose: (result: string) => void,
}

export default function Modal({ children, onClose, id, className }: ModalProps) {
    const blur: React.MouseEventHandler<HTMLDivElement> = (evt) => {
        evt.preventDefault();
        onClose("cancelled");
    }

    if (children) {
        return createPortal(
            <div className={`modal-backdrop`}
                onMouseDown={blur}
            >
                <div id={id} className={`modal ${className ?? ""}`}
                    //This prevents the backdrop from closing the modal when we click on it
                    onMouseDown={evt => { evt.stopPropagation() }}
                >
                    {children}
                </div>
            </div>,
            document.getElementById('portal')!
        )
    } else return null;
}
