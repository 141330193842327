import React, { createContext, ReactNode, useContext, useState } from 'react'
import Modal from '../../Components/Overlays/Modal';

interface ModalContextProps {
    openModal: (view: React.ReactNode) => void,
    closeModal: () => void
}

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);
export const useModal = () => useContext(ModalContext);

export function ModalProvider({ children }) {
    const [content, setContent] = useState<ReactNode | null>(null);

    //TODO Make this return a promise with the selected value
    function openModal<T>(view: React.ReactNode) {
        setContent(view)
    }
    function closeModal() {
        setContent(null);
    }

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <Modal onClose={closeModal}>
                {content}
            </Modal>
        </ModalContext.Provider>
    )
}