import React from 'react'

export default function Subscriptions() {
    return (
        <div className='subscriptions-container'>
            Give me your money, kid
            <ul>
                <li>$5 per month = Share with friends (or foes)</li>
                <li>$50 per month = Feel extra good about being a supporter</li>
            </ul>
        </div>
    )
}
