import { useUser } from '../Logic_Client';
import React from 'react'

/**
 * Shows content only for users with the given role
 */
export default function RoleElement({ role, children }: { role: string, children: React.ReactNode }) {
    const { user } = useUser();
    if (!user) return null;

    if (user.hasProductRole(role))
        return (
            <>
                {children}
            </>
        )
    else return null;
}
