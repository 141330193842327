import axios from 'axios';
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth';
import { CreateUserRequest } from './Logic_Server/RequestInterfaces/UserRequestInterfaces';

const firebaseConfig = {
  apiKey: "AIzaSyDdOhqIUH9YGh_Ux4gn1SYgFLChqM-6xSQ",
  authDomain: "wayfinder-67a0a.firebaseapp.com",
  projectId: "wayfinder-67a0a",
  storageBucket: "wayfinder-67a0a.appspot.com",
  messagingSenderId: "769874706637",
  appId: "1:769874706637:web:25059f65cdd00c4c026984",
  measurementId: "G-X6CESPBT82"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const authToken = await res.user.getIdToken();
    const req: CreateUserRequest = {
      method: "POST",
      body: {
        method: "CREATE",
        authToken
      }
    }
    axios.post("/api/users", req.body)
      .catch(err => {
        console.error(err.response)
      })
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}

const signInWithEmail = async (email: string | undefined, password: string | undefined) => {
  if (!email || !password) {
    throw new Error("Both email and password required");
  }

  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const authToken = await res.user.getIdToken();

    const req: CreateUserRequest = {
      method: "POST",
      body: {
        method: "CREATE",
        authToken
      }
    }
    axios.post("/api/users", req.body)
      .catch(err => {
        console.error(err.response)
      })
  } catch (err: any) {
    console.error(err)
  }
}

const signUpWithEmail = async (email: string | undefined, password: string | undefined) => {
  if (!email || !password) {
    throw new Error("Both email and password required");
  }

  const res = await createUserWithEmailAndPassword(auth, email, password);
  const authToken = await res.user.getIdToken();

  const req: CreateUserRequest = {
    method: "POST",
    body: {
      method: "CREATE",
      authToken
    }
  }
  axios.post("/api/users", req.body)
    .catch(err => {
      console.error(err.response)
    });
}

const sendPasswordReset = async (email: string) => {
  sendPasswordResetEmail(auth, email);
}

const logout = () => {
  console.log(`Logging ${auth.currentUser?.email} out`)
  signOut(auth);
}

export {
  auth,
  signInWithGoogle,
  signInWithEmail,
  signUpWithEmail,
  sendPasswordReset,
  logout,
}