import { useMemo, useRef, useState } from "react";

export default function useDebounced<A extends any, R extends any>(callback: (args: A) => R, delayMs: number = 1000, dependencies: any[] = []): (args: A) => Promise<R> {
    const time = useRef<NodeJS.Timeout>();

    return useMemo(() => (args: A) => {
        clearTimeout(time.current);
        // TODO I'm not sure the best way to do this. This returns a new promise every call, which will mean a new subscription every call...
        return new Promise((res, rej) => {
            time.current = setTimeout(() => {
                res(callback(args));
            }, delayMs);
        })
    }, dependencies);
}


/**
 * Allows multiple debounced functions to coexist, separated by {id:string}
 * so requests don't overwrite previous requests for different objects
 */
export function useDebouncedById<A extends { id: any }, R extends any>(callback: (args: A) => R, delayMs: number = 1000, dependencies: any[] = []): (args: A) => Promise<R> {
    const waiting = useRef<Map<any, NodeJS.Timeout>>(new Map());

    return useMemo(() => (args: A) => {
        if (waiting.current.has(args.id)) {
            clearTimeout(waiting.current.get(args.id));
        }

        // TODO I'm not sure the best way to do this. This returns a new promise every call, which will mean a new subscription every call...
        return new Promise((res, rej) => {
            waiting.current.set(args.id, setTimeout(() => {
                res(callback(args));
            }, delayMs));
        })
    }, dependencies);
}

/**
 * Allows multiple debounced functions to coexist, separated by {id:string}
 * so requests don't overwrite previous requests for different objects
 */
export function useIdGroupedDebounce<A extends { id: any }, R extends any>(callback: (args: A[]) => R, delayMs: number = 1000, dependencies: any[] = []): (args: A) => Promise<R> {
    const groupedArgs = useRef<Map<any, A>>(new Map());
    const time = useRef<NodeJS.Timeout>();

    return useMemo(() => (args: A) => {

        return new Promise((res, rej) => {
            clearTimeout(time.current);

            groupedArgs.current.set(args.id, args);
            time.current = setTimeout(() => {
                res(callback(Array.from(groupedArgs.current.values())));
                groupedArgs.current.clear();
            }, delayMs)
        })
    }, dependencies);
};
