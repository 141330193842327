import { Prisma, SubtreeData } from "prisma/prisma-client";
import { InheritanceMode } from "../../Logic_Server/DTOs";
import API from '../../Logic_Client/AxiosWrapper';
import { GetNodesById, GetNodesResponse, GetSubtreeDataRequest, GetSubtreeDataResponse } from "../../Logic_Server/RequestInterfaces";
import { DTOTools } from ".";

export default class DataNode<T extends any> {
    get Permissions(): string[] {
        if (!this.subtreeData) {
            throw new Error(`Cannot create node permissions: SubtreeData not fetched on ${this.title}`)
        }

        if (!this._permissions) {
            this._permissions = this.subtreeData.permissions.filter(x => x[0] !== InheritanceMode.removed)
        }
        return this._permissions!;
    }
    get Tags(): string[] {
        if (!this.subtreeData) {
            throw new Error(`Cannot parse tags: SubtreeData not fetched on ${this.title}`)
        }

        if (!this._tags) {
            this._tags = this.subtreeData.tags.filter(x => x[0] !== InheritanceMode.removed).map(x => x.substring(1))
        }
        return this._tags!;
    }

    _tags?: string[];
    _permissions?: string[];

    constructor(
        public id: string,
        public userId: string,
        public title: string,
        public data: T,
        public dataType: string,
        public content: any,
        public lastFetched: Date,

        public subtreeDataId: string,
        public parentIds: string[],
        public childrenIds: string[],

        public subtreeData?: SubtreeData,
        public parents?: DataNode<any>[],
        public children?: DataNode<any>[],
    ) {
        this.lastFetched = lastFetched ?? new Date();
    }

    async fetchRelations(authToken: string): Promise<DataNode<any>[]> {
        if (this.parents && this.children) {
            return Promise.resolve([...this.parents, ...this.children]);
        } else if (this.parentIds.length > 0 || this.childrenIds.length > 0) {
            const req: GetNodesById = {
                method: "GET",
                query: {
                    authToken,
                    nodeIds: [...this.parentIds, ...this.childrenIds]
                }
            }
            const family = await API<GetNodesResponse>("/api/nodes", req).then(res => res.found.map(x => DTOTools.DataNodeDTOToObject(x)));

            this.parents = family.filter(x => this.parentIds.includes(x.id));
            this.children = family.filter(x => this.childrenIds.includes(x.id));
            return [...this.parents!, ...this.children!];
        } else {
            this.parents = [];
            this.children = [];
            return Promise.resolve([]);
        }
    }

    async fetchParents(authToken: string): Promise<DataNode<any>[]> {
        if (this.parents) {
            return Promise.resolve(this.parents);
        } else if (this.parentIds.length > 0) {
            const req: GetNodesById = {
                method: "GET",
                query: {
                    authToken,
                    nodeIds: this.parentIds
                }
            }
            this.parents = await API<GetNodesResponse>("/api/nodes", req).then(res => res.found.map(x => DTOTools.DataNodeDTOToObject(x)));
            return this.parents!;
        } else {
            this.parents = [];
            return Promise.resolve([]);
        }
    }
    async fetchChildren(authToken: string): Promise<DataNode<any>[]> {
        if (this.children) {
            return Promise.resolve(this.children);
        } else if (this.childrenIds.length > 0) {
            const req: GetNodesById = {
                method: "GET",
                query: {
                    authToken,
                    nodeIds: this.childrenIds
                }
            }
            this.children = await API<GetNodesResponse>("/api/nodes", req).then(res => res.found.map(x => DTOTools.DataNodeDTOToObject(x)));
            return this.children!;
        } else {
            this.children = [];
            return Promise.resolve([]);
        }
    }
    async fetchSubtreeData(authToken: string): Promise<SubtreeData> {
        const req: GetSubtreeDataRequest = {
            method: "GET",
            query: {
                dataId: this.subtreeDataId
            }
        }
        await API<GetSubtreeDataResponse>("/api/nodes/subtreeData", req).then(res => this.subtreeData = res.found)
        return this.subtreeData!;
    }

    clone(): DataNode<T> {
        return new DataNode(
            this.id,
            this.userId,
            this.title,
            this.data,
            this.dataType,
            this.content,
            this.lastFetched,
            this.subtreeDataId,
            this.parentIds,
            this.childrenIds,
            this.subtreeData,
            this.parents,
            this.children
        );
    }
}